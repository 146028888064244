import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import Withdraw from '../../models/Withdraw';
import ShowWithdrawRequest from '../../network/requests/ShowWithdrawRequest';
import UpdateWithdrawRequest from '../../network/requests/UpdateWithdrawRequest';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('withdraws/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, Withdraw[]>('/withdraws');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('withdraws/show', async ({ id }: ShowWithdrawRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, Withdraw>(`/withdraws/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const update = createAsyncThunk('withdraws/update', async ({ id, ...payload }: UpdateWithdrawRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UpdateWithdrawRequest, 'id'>, Withdraw>(`/withdraws/${id}`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const withdrawsAsyncActions = {
	index,
	show,
	update,
};

export default withdrawsAsyncActions;
