import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { CardsState, CPA } from 'store/types';
import { Timing } from '../../lib/util';
import Card from '../../models/Card';
import cardsAsyncActions from '../actions/cards.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: CardsState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'cards',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[cardsAsyncActions.index.fulfilled.type]: (state, action: CPA<Card[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardsAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardsAsyncActions.show.fulfilled.type]: (state, action: CPA<Card>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardsAsyncActions.show.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardsAsyncActions.update.fulfilled.type]: (state, action: CPA<Card>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardsAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardsAsyncActions.destroy.fulfilled.type]: (state, action: CPA<string>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardsAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
	},
});

export const cardsActions = slice.actions;

export default slice.reducer;
