import { validateEmail } from '@cyboticx/common';
import { useEnterKeyPress } from '@cyboticx/hooks';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
} from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import SortableTable from '../../lib/components/SortableTable';
import { isAnyEmpty } from '../../lib/util';
import Roles from '../../lib/util/Roles';
import Card from '../../models/Card';
import { useAppDispatch } from '../../store';
import usersAsyncActions from '../../store/actions/users.action';
import RequestManager from '../../store/request-manager';
import { useRequestState, useUsersState } from '../../store/selectors';

interface Props {}

const UserUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useParams<{
		id: string;
	}>();

	const usersState = useUsersState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [roleId, setRoleId] = React.useState<Roles>(Roles.USER);
	const [availableCards, setAvailableCards] = React.useState<number>(0);
	const [cards, setCards] = React.useState<Card[]>([]);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([firstName, lastName, email]);
	}, [firstName, lastName, email]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		if (!validateEmail(email)) {
			toast.error('Invalid email address.');
			return;
		}

		setIsSaving(true);
		dispatch(
			usersAsyncActions.update({ id: params.id, firstName, lastName, email, password, roleId, availableCards })
		);
	}, [canProceed, firstName, lastName, email, password, roleId, availableCards]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(usersAsyncActions.show.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(usersAsyncActions.show.typePrefix)) {
			RM.consume(usersAsyncActions.show.typePrefix);

			const user = usersState.list.find((element) => parseInt(element.id, 10) === parseInt(params.id, 10));
			if (user) {
				setFirstName(user.firstName);
				setLastName(user.lastName);
				setEmail(user.email);
				setRoleId(user.roleId);
				setAvailableCards(user.availableCards);
				setCards(user.cards);
			}
		}

		if (RM.isFinished(usersAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(usersAsyncActions.update.typePrefix)) {
			RM.consume(usersAsyncActions.update.typePrefix);

			toast.success('User saved successfully.');
			history.goBack();
		}
	}, [usersState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(usersAsyncActions.show({ id: params.id }));
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ overflow: 'hidden' }}>
				<Box px={2} py={1} maxWidth={'sm'} component="form" noValidate>
					<FormControl margin="normal" fullWidth>
						<InputLabel id="role-select-label">Role</InputLabel>
						<Select
							labelId="role-select-label"
							id="role-select"
							value={roleId}
							label="Role"
							onChange={(e) => setRoleId(parseInt(e.target.value as string, 10) as Roles)}
						>
							<MenuItem value={Roles.USER}>User</MenuItem>
							<MenuItem value={Roles.ADMIN}>Admin</MenuItem>
							<MenuItem value={Roles.SUPER_ADMIN}>Super Admin</MenuItem>
						</Select>
					</FormControl>
					<TextField
						margin="normal"
						required
						fullWidth
						name="first-name"
						label="First Name"
						type="text"
						id="first-name"
						autoComplete="first-name"
						autoFocus
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="last-name"
						label="Last Name"
						type="text"
						id="last-name"
						autoComplete="last-name"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						type="email"
						autoComplete="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="password"
						label="Password"
						name="password"
						type="password"
						autoComplete="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="available-cards"
						label="Available Cards"
						name="available-cards"
						type="number"
						autoComplete="available-cards"
						value={availableCards}
						onChange={(e) => setAvailableCards(parseInt(e.target.value, 10))}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
				{cards.length > 0 && (
					<SortableTable
						noSelection
						onRowClick={() => {}}
						onDeleteClick={() => {}}
						title={'Cards'}
						data={cards}
						header={[
							{
								id: 'recipientFullName',
								label: 'Name',
								numeric: false,
								disablePadding: true,
							},
							{
								id: 'recipientEmail',
								label: 'E-Mail',
								numeric: false,
								disablePadding: false,
							},
							{
								id: 'isContributionEnabled',
								label: 'Contribution Enabled',
								numeric: false,
								boolean: true,
								disablePadding: false,
							},
							{
								id: 'collectedAmount',
								label: 'Collected',
								numeric: true,
								disablePadding: false,
								currency: true,
							},
							{
								id: 'deliveredAt',
								label: 'Delivered At',
								numeric: false,
								disablePadding: false,
							},
						]}
					/>
				)}
			</Paper>
		</BasicLayout>
	);
};

export default UserUpdateScreen;
