import { LS } from '@cyboticx/common';
import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { API } from 'lib/util';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import ErrorResponse from 'network/responses/ErrorResponse';
import { toast } from 'react-hot-toast';
import authenticationAsyncActions from 'store/actions/authentication.action';
import userAsyncActions from 'store/actions/user.action';
import { AuthenticationState, CPA } from 'store/types';
import DeviceTypes from '../../lib/util/DeviceTypes';
import { requestActions } from './request.slice';

const initialState: AuthenticationState = {
	isAuthenticated: false,
	accessToken: '',
	expiryAt: -1,
	deviceType: DeviceTypes.WEB,
};

const fillState = (state: WritableDraft<AuthenticationState>, { payload }: CPA<AuthenticationResponse>) => {
	state.isAuthenticated = true;
	state.accessToken = payload.accessToken;
	state.expiryAt = payload.expiryAt;
	state.deviceType = payload.deviceType;

	LS.addAccessToken(payload.accessToken);
	API.addAccessToken(payload.accessToken);
};

const slice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		restoreAccessToken: (state) => {
			API.addAccessToken(state.accessToken === '' ? undefined : state.accessToken);
		},
		removeAuthState: () => {
			API.removeAccessToken();
			return initialState;
		},
	},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: (state, action: CPA<AuthenticationResponse>) => {
			fillState(state, action);
			action.dispatch(
				requestActions.fulfilled({
					name: authenticationAsyncActions.signIn.typePrefix,
					message: '',
					payload: {},
				})
			);
		},
		[authenticationAsyncActions.signIn.rejected.type]: (_, { payload, dispatch }: CPA<ErrorResponse>) => {
			const message = payload.error.list[0].msg;
			if (message.match(/invalid(\s|\_)credentials/is)) toast.error('Invalid Credentials. Try again!');
			dispatch(
				requestActions.rejected({
					name: authenticationAsyncActions.signIn.typePrefix,
					message,
					payload: { ...payload.error },
				})
			);
		},
		[authenticationAsyncActions.signOut.rejected.type]: (state, action: CPA<ErrorResponse>) => {
			toast.success('You have been logged out.');

			state.isAuthenticated = false;
			state.accessToken = '';
			state.expiryAt = -1;

			LS.removeAccessToken();
			API.removeAccessToken();

			action.dispatch(
				requestActions.rejected({
					name: authenticationAsyncActions.signOut.typePrefix,
					message: 'You have been logged out.',
					payload: { ...action.payload.error },
				})
			);
		},
		[authenticationAsyncActions.signOut.fulfilled.type]: (state, action: CPA) => {
			toast.success('You have been logged out.');

			state.isAuthenticated = false;
			state.accessToken = '';
			state.expiryAt = -1;

			LS.removeAccessToken();
			API.removeAccessToken();

			action.dispatch(
				requestActions.fulfilled({
					name: authenticationAsyncActions.signOut.typePrefix,
					message: 'You have been logged out.',
					payload: { ...action.payload.error },
				})
			);
		},
		[userAsyncActions.refreshUser.rejected.type]: (state, { payload }: PA<ErrorResponse>) => {
			if (payload.error.status === 401) {
				state = initialState;
				LS.removeAccessToken();
				API.removeAccessToken();
			}
		},
		[authenticationAsyncActions.forgotPassword.fulfilled.type]: (_, { dispatch }: CPA) => {
			toast.success('Password reset email sent.');
			dispatch(
				requestActions.fulfilled({
					name: authenticationAsyncActions.forgotPassword.typePrefix,
					message: '',
					payload: {},
				})
			);
		},
		[authenticationAsyncActions.forgotPassword.rejected.type]: (_, { payload, dispatch }: CPA<ErrorResponse>) => {
			const message = payload.error.list[0].msg;
			if (message === 'invalid_email') toast.error('Invalid email.');
			else toast.error('Error sending email.');

			dispatch(
				requestActions.rejected({
					name: authenticationAsyncActions.forgotPassword.typePrefix,
					message,
					payload: { ...payload.error },
				})
			);
		},
		[authenticationAsyncActions.resetPassword.fulfilled.type]: (state, action: CPA<AuthenticationResponse>) => {
			fillState(state, action);
			toast.success('Password reset successful.');

			action.dispatch(
				requestActions.fulfilled({
					name: authenticationAsyncActions.resetPassword.typePrefix,
					message: '',
					payload: {},
				})
			);
		},
		[authenticationAsyncActions.resetPassword.rejected.type]: (_, { payload, dispatch }: CPA<ErrorResponse>) => {
			const message = payload.error.list[0].msg;
			if (message === 'invalid_code') {
				toast.error('Invalid code.');
			} else if (message === 'unknown_user') toast.error('Sorry, we do not recognize that account.');
			else if (message === 'passwords_mismatch') toast.error('Passwords mismatch. Try again.');
			else toast.error('Error resetting password. Try again.');

			dispatch(
				requestActions.rejected({
					name: authenticationAsyncActions.resetPassword.typePrefix,
					message,
					payload: { ...payload.error },
				})
			);
		},
	},
});

export const authenticationActions = slice.actions;

export default slice.reducer;
