import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import CardCategory from '../../models/CardCategory';
import CreateCardCategoryRequest from '../../network/requests/CreateCardCategoryRequest';
import DeleteCardCategoryRequest from '../../network/requests/DeleteCardCategoryRequest';
import ShowCardCategoryRequest from '../../network/requests/ShowCardCategoryRequest';
import UpdateCardCategoryRequest from '../../network/requests/UpdateCardCategoryRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('card-categories/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, CardCategory[]>('/card-categories');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('card-categories/show', async ({ id }: ShowCardCategoryRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, CardCategory>(`/card-categories/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const store = createAsyncThunk('card-categories/store', async (payload: CreateCardCategoryRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<CreateCardCategoryRequest, CardCategory>(`/card-categories`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk(
	'card-categories/update',
	async ({ id, ...payload }: UpdateCardCategoryRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(update.typePrefix));
		try {
			const response = await API.put<Omit<UpdateCardCategoryRequest, 'id'>, CardCategory>(
				`/card-categories/${id}`,
				payload
			);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const destroy = createAsyncThunk('card-categories/destroy', async ({ id }: DeleteCardCategoryRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<never, OkResponse>(`/card-categories/${id}`);

		return id;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const cardCategoriesAsyncActions = {
	index,
	show,
	store,
	update,
	destroy,
};

export default cardCategoriesAsyncActions;
