import { useEnterKeyPress } from '@cyboticx/hooks';
import { DateTimePicker } from '@mui/lab';
import { Box, Button, CircularProgress, FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import { isAnyEmpty } from '../../lib/util';
import { useAppDispatch } from '../../store';
import cardCategoriesAsyncActions from '../../store/actions/cardCategories.action';
import RequestManager from '../../store/request-manager';
import { useCardCategoriesState, useRequestState } from '../../store/selectors';

interface Props {}

const CardCategoryUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useParams<{
		id: string;
	}>();

	const cardCategoriesState = useCardCategoriesState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>('');
	const [isFeatured, setIsFeatured] = React.useState<boolean>(false);
	const [isVisible, setIsVisible] = React.useState<boolean>(true);
	const [availableFrom, setAvailableFrom] = React.useState<string | null>(null);
	const [availableTo, setAvailableTo] = React.useState<string | null>(null);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([name]);
	}, [name]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			cardCategoriesAsyncActions.update({
				id: params.id,
				name,
				isFeatured,
				isVisible,
				availableFrom,
				availableTo,
			})
		);
	}, [canProceed, name, isFeatured, isVisible, availableFrom, availableTo]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardCategoriesAsyncActions.show.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardCategoriesAsyncActions.show.typePrefix)) {
			RM.consume(cardCategoriesAsyncActions.show.typePrefix);

			const category = cardCategoriesState.list.find(
				(element) => parseInt(element.id, 10) === parseInt(params.id, 10)
			);
			if (category) {
				setName(category.name);
				setIsFeatured(category.isFeatured);
				setIsVisible(category.isVisible);
				setAvailableFrom(
					category.availableFrom ? moment.utc(category.availableFrom).format('YYYY-MM-DD hh:mm A') : null
				);
				setAvailableTo(
					category.availableTo ? moment.utc(category.availableTo).format('YYYY-MM-DD hh:mm A') : null
				);
			}
		}

		if (RM.isFinished(cardCategoriesAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(cardCategoriesAsyncActions.update.typePrefix)) {
			RM.consume(cardCategoriesAsyncActions.update.typePrefix);

			toast.success('Card Category saved successfully.');
			history.goBack();
		}
	}, [cardCategoriesState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardCategoriesAsyncActions.show({ id: params.id }));
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ px: 2, py: 1, overflow: 'hidden' }}>
				<Box maxWidth={'sm'} component="form" noValidate>
					<TextField
						margin="normal"
						required
						fullWidth
						name="name"
						label="Name"
						type="text"
						id="name"
						autoComplete="name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<FormControlLabel
						control={<Switch checked={isFeatured} onChange={(_, newValue) => setIsFeatured(newValue)} />}
						label="Is Featured"
					/>
					<FormControlLabel
						control={<Switch checked={isVisible} onChange={(_, newValue) => setIsVisible(newValue)} />}
						label="Is Visible"
					/>
					<DateTimePicker
						renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
						label="Available From"
						value={availableFrom ? moment.utc(availableFrom, 'YYYY-MM-DD hh:mm A') : null}
						inputFormat={'DD/MM/YYYY hh:mm A'}
						onChange={(value) => {
							setAvailableFrom(value ? value.format('YYYY-MM-DD hh:mm A') : null);
						}}
					/>
					<DateTimePicker
						renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
						label="Available To"
						value={availableTo ? moment.utc(availableTo, 'YYYY-MM-DD hh:mm A') : null}
						inputFormat={'DD/MM/YYYY hh:mm A'}
						onChange={(value) => {
							setAvailableTo(value ? value.format('YYYY-MM-DD hh:mm A') : null);
						}}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default CardCategoryUpdateScreen;
