import { useEnterKeyPress } from '@cyboticx/hooks';
import { DateTimePicker } from '@mui/lab';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import { isAnyEmpty } from '../../lib/util';
import { useAppDispatch } from '../../store';
import cardCategoriesAsyncActions from '../../store/actions/cardCategories.action';
import cardTemplatesAsyncActions from '../../store/actions/cardTemplates.action';
import RequestManager from '../../store/request-manager';
import { useCardCategoriesState, useRequestState } from '../../store/selectors';

interface Props {}

const CardTemplateCreateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const cardCategoriesState = useCardCategoriesState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [categoryId, setCategoryId] = React.useState<string>('');
	const [name, setName] = React.useState<string>('');
	const [picture, setPicture] = React.useState<File | null>(null);
	const [isFeatured, setIsFeatured] = React.useState<boolean>(false);
	const [isVisible, setIsVisible] = React.useState<boolean>(true);
	const [availableFrom, setAvailableFrom] = React.useState<string | null>(null);
	const [availableTo, setAvailableTo] = React.useState<string | null>(null);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([categoryId, name]) && picture !== null;
	}, [categoryId, name, picture]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			cardTemplatesAsyncActions.store({
				categoryId,
				name,
				picture: picture!,
				isFeatured,
				isVisible,
				availableFrom,
				availableTo,
			})
		);
	}, [canProceed, categoryId, name, picture, isFeatured, isVisible, availableFrom, availableTo]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardCategoriesAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardCategoriesAsyncActions.index.typePrefix)) {
			RM.consume(cardCategoriesAsyncActions.index.typePrefix);
		}

		if (RM.isFinished(cardTemplatesAsyncActions.store.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(cardTemplatesAsyncActions.store.typePrefix)) {
			RM.consume(cardTemplatesAsyncActions.store.typePrefix);

			toast.success('Card Template saved successfully.');
			history.goBack();
		}
	}, [cardCategoriesState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardCategoriesAsyncActions.index());
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ px: 2, py: 1, overflow: 'hidden' }}>
				<Grid container px={2} py={1}>
					<Grid item xs={12} sm={6}>
						<Box component="form" noValidate>
							<FormControl margin="normal" fullWidth>
								<InputLabel id="category-select-label">Category</InputLabel>
								<Select
									labelId="category-select-label"
									id="category-select"
									value={categoryId}
									label="Category"
									onChange={(e) => setCategoryId(e.target.value)}
								>
									{cardCategoriesState.list.map((element) => (
										<MenuItem key={element.id} value={element.id}>
											{element.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								margin="normal"
								required
								fullWidth
								name="name"
								label="Name"
								type="text"
								id="name"
								autoComplete="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<Box my={1} display="flex" alignItems={'center'}>
								<Button variant="contained" component="label">
									{picture ? 'Change' : 'Upload'} Picture
									<input
										accept="image/*"
										type="file"
										hidden
										onChange={(e) => {
											if (e.target.files && e.target.files.length > 0) {
												setPicture(e.target.files[0]);
											}
										}}
									/>
								</Button>
								<Box mx={1} />
								{picture && <Typography variant="body2">{picture.name}</Typography>}
							</Box>
							<FormControlLabel
								control={
									<Switch checked={isFeatured} onChange={(_, newValue) => setIsFeatured(newValue)} />
								}
								label="Is Featured"
							/>
							<FormControlLabel
								control={
									<Switch checked={isVisible} onChange={(_, newValue) => setIsVisible(newValue)} />
								}
								label="Is Visible"
							/>
							<DateTimePicker
								renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
								label="Available From"
								value={availableFrom ? moment.utc(availableFrom, 'YYYY-MM-DD hh:mm A') : null}
								inputFormat={'DD/MM/YYYY hh:mm A'}
								onChange={(value) => {
									setAvailableFrom(value ? value.format('YYYY-MM-DD hh:mm A') : null);
								}}
							/>
							<DateTimePicker
								renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
								label="Available To"
								value={availableTo ? moment.utc(availableTo, 'YYYY-MM-DD hh:mm A') : null}
								inputFormat={'DD/MM/YYYY hh:mm A'}
								onChange={(value) => {
									setAvailableTo(value ? value.format('YYYY-MM-DD hh:mm A') : null);
								}}
							/>
							<Button
								disabled={!canProceed || isSaving}
								onClick={handleSubmit}
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								{isSaving && <CircularProgress size={16} />}&nbsp;
								{!isSaving && 'Save'}
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="center">
						{picture !== null && (
							<div
								style={{
									position: 'relative',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: 'calc(420px * 0.75)',
									height: '420px',
								}}
							>
								<img
									style={{
										borderRadius: '8px',
										boxShadow:
											'0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
										width: '100%',
										height: '100%',
										maxWidth: '100%',
										objectFit: 'contain',
									}}
									src={picture instanceof File ? URL.createObjectURL(picture) : picture}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			</Paper>
		</BasicLayout>
	);
};

export default CardTemplateCreateScreen;
