import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthenticationState } from 'store/selectors';
import { Timing } from 'lib/util';

interface Props extends RouteProps {
	forbidAuthenticated?: boolean;
}

const Public = ({ path, component, forbidAuthenticated = false, ...rest }: Props) => {
	const { isAuthenticated, expiryAt } = useAuthenticationState();

	return isAuthenticated && !Timing.isExpired(expiryAt) && forbidAuthenticated ? (
		<Redirect to="/users" />
	) : (
		<Route path={path} component={component} {...rest} />
	);
};

export default Public;
