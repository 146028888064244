import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { CompaniesState, CPA } from 'store/types';
import { Timing } from '../../lib/util';
import Company from '../../models/Company';
import companiesAsyncActions from '../actions/companies.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: CompaniesState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'companies',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[companiesAsyncActions.index.fulfilled.type]: (state, action: CPA<Company[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[companiesAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[companiesAsyncActions.show.fulfilled.type]: (state, action: CPA<Company>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[companiesAsyncActions.show.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[companiesAsyncActions.store.fulfilled.type]: (state, action: CPA<Company>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[companiesAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[companiesAsyncActions.update.fulfilled.type]: (state, action: CPA<Company>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[companiesAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[companiesAsyncActions.destroy.fulfilled.type]: (state, action: CPA<string>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[companiesAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
	},
});

export const companiesActions = slice.actions;

export default slice.reducer;
