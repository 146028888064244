import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SortableTable from '../../lib/components/SortableTable';
import CardCategory from '../../models/CardCategory';
import { useAppDispatch } from '../../store';
import cardCategoriesAsyncActions from '../../store/actions/cardCategories.action';
import RequestManager from '../../store/request-manager';
import { useCardCategoriesState, useRequestState } from '../../store/selectors';

interface Props {}

const CardCategoriesTab: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const cardCategoriesState = useCardCategoriesState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [query, setQuery] = React.useState<string>('');

	const data = React.useMemo<CardCategory[]>(() => {
		if (query.trim().length <= 0) {
			return cardCategoriesState.list;
		}

		return cardCategoriesState.list.filter((element) => element.name.trim().toLowerCase().includes(query));
	}, [cardCategoriesState.list, query]);

	const handleRefresh = React.useCallback(() => {
		dispatch(cardCategoriesAsyncActions.index());
	}, [dispatch]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardCategoriesAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardCategoriesAsyncActions.index.typePrefix)) {
			RM.consume(cardCategoriesAsyncActions.index.typePrefix);
		}
	}, [cardCategoriesAsyncActions, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardCategoriesAsyncActions.index());
	}, []);

	if (isLoading) {
		return (
			<Box
				component="main"
				height={'100%'}
				sx={{
					flex: 1,
					py: 6,
					px: 4,
					bgcolor: '#eaeff1',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
			<AppBar
				position="static"
				color="default"
				elevation={0}
				sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
			>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<SearchIcon color="inherit" sx={{ display: 'block' }} />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								placeholder="Search by name"
								InputProps={{
									disableUnderline: true,
									sx: { fontSize: 'default' },
								}}
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								variant="standard"
							/>
						</Grid>
						<Grid item>
							<Button
								onClick={() => history.push('/card-categories/create')}
								variant="contained"
								sx={{ mr: 1 }}
							>
								Add category
							</Button>
							<Tooltip title="Reload">
								<IconButton onClick={handleRefresh}>
									<RefreshIcon color="inherit" sx={{ display: 'block' }} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{data.length === 0 && (
				<Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
					No categories found.
				</Typography>
			)}
			{data.length > 0 && (
				<SortableTable
					onRowClick={(id: string) => history.push(`/card-categories/${id}/update`)}
					onDeleteClick={(ids: string[]) =>
						ids.forEach((id) => dispatch(cardCategoriesAsyncActions.destroy({ id })))
					}
					title={'Card Categories'}
					data={data}
					header={[
						{
							id: 'name',
							label: 'Name',
							numeric: false,
							disablePadding: true,
						},
						{
							id: 'templatesAmount',
							label: 'Templates Amount',
							numeric: true,
							disablePadding: false,
						},
						{
							id: 'isFeatured',
							label: 'Featured',
							numeric: false,
							boolean: true,
							disablePadding: false,
						},
						{
							id: 'isVisible',
							label: 'Visible',
							numeric: false,
							boolean: true,
							disablePadding: false,
						},
					]}
				/>
			)}
		</Paper>
	);
};

export default CardCategoriesTab;
