import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import authenticationAsyncActions from '../../../store/actions/authentication.action';

const categories = [
	{
		id: 'Main',
		children: [
			{
				id: 'Users',
				path: '/users',
				icon: <PeopleIcon />,
			},
			{
				id: 'Companies',
				path: '/companies',
				icon: <BusinessIcon />,
			},
			{
				id: 'Card Packs',
				path: '/card-packs',
				icon: <DynamicFeedIcon />,
			},
			{ id: 'Cards', path: '/cards', activeWhen: ['/card-categories', '/card-templates'], icon: <ArticleIcon /> },
			{ id: 'Withdraws', path: '/withdraws', icon: <AccountBalanceWalletIcon /> },
			{ id: 'Settings', path: '/settings', icon: <SettingsIcon /> },
		],
	},
];

const item = {
	py: '2px',
	px: 3,
	color: 'rgba(255, 255, 255, 0.7)',
	'&:hover, &:focus': {
		bgcolor: 'rgba(255, 255, 255, 0.08)',
	},
};

const itemCategory = {
	boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
	py: 1.5,
	px: 3,
};

interface Props extends DrawerProps {}

const Navigation: React.FC<Props> = (props: Props) => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const { pathname } = useLocation();
	const { ...other } = props;

	const isActive = React.useCallback(
		(path, activeWhen: string[]) => {
			let active = pathname.includes(path);
			if (!active) {
				for (const entry of activeWhen) {
					active = pathname.includes(entry);
					if (active) {
						break;
					}
				}
			}

			return active;
		},
		[pathname]
	);

	return (
		<Drawer variant="permanent" {...other}>
			<List disablePadding>
				<ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>Sign the Card</ListItem>
				{categories.map(({ id, children }) => (
					<Box key={id} sx={{ bgcolor: '#101F33' }}>
						<ListItem sx={{ py: 2, px: 3 }}>
							<ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
						</ListItem>
						{children.map(({ id: childId, icon, activeWhen = [], path }) => (
							<ListItem disablePadding key={childId}>
								<ListItemButton
									onClick={() => history.push(path)}
									selected={isActive(path, activeWhen)}
									sx={item}
								>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText>{childId}</ListItemText>
								</ListItemButton>
							</ListItem>
						))}
						<Box my={1} />
						<Divider />
						<Box my={1} />
						<ListItem disablePadding>
							<ListItemButton onClick={() => dispatch(authenticationAsyncActions.signOut())} sx={item}>
								<ListItemIcon>
									<ExitToAppIcon />
								</ListItemIcon>
								<ListItemText>Sign Out</ListItemText>
							</ListItemButton>
						</ListItem>
						<Divider sx={{ mt: 2 }} />
					</Box>
				))}
			</List>
		</Drawer>
	);
};

export default Navigation;
