import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import Card from '../../models/Card';
import DeleteCardRequest from '../../network/requests/DeleteCardRequest';
import ShowCardRequest from '../../network/requests/ShowCardRequest';
import UpdateCardRequest from '../../network/requests/UpdateCardRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('cards/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, Card[]>('/cards');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('cards/show', async ({ id }: ShowCardRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, Card>(`/cards/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const update = createAsyncThunk('cards/update', async ({ id, ...payload }: UpdateCardRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UpdateCardRequest, 'id'>, Card>(`/cards/${id}`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const destroy = createAsyncThunk('cards/destroy', async ({ id }: DeleteCardRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<never, OkResponse>(`/cards/${id}`);

		return id;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const cardsAsyncActions = {
	index,
	show,
	update,
	destroy,
};

export default cardsAsyncActions;
