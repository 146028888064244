import { validateEmail } from '@cyboticx/common';
import { useEnterKeyPress } from '@cyboticx/hooks';
import { DateTimePicker } from '@mui/lab';
import { Box, Button, CircularProgress, Grid, Paper, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import SortableTable from '../../lib/components/SortableTable';
import { isAnyEmpty } from '../../lib/util';
import Contributor from '../../models/Contributor';
import { useAppDispatch } from '../../store';
import cardsAsyncActions from '../../store/actions/cards.action';
import RequestManager from '../../store/request-manager';
import { useCardsState, useRequestState } from '../../store/selectors';

interface Props {}

const CardUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useParams<{
		id: string;
	}>();

	const cardsState = useCardsState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [uuid, setUuid] = React.useState<string>('');
	const [recipientFirstName, setRecipientFirstName] = React.useState<string>('');
	const [recipientLastName, setRecipientLastName] = React.useState<string>('');
	const [recipientEmail, setRecipientEmail] = React.useState<string>('');
	const [reminderMessage, setReminderMessage] = React.useState<string>('');
	const [deliveryAt, setDeliveryAt] = React.useState<string>('');
	const [picture, setPicture] = React.useState<string>('');
	const [contributors, setContributors] = React.useState<Contributor[]>([]);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([recipientFirstName, recipientLastName, recipientEmail, reminderMessage, deliveryAt]);
	}, [recipientFirstName, recipientLastName, recipientEmail, reminderMessage, deliveryAt]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		if (!validateEmail(recipientEmail)) {
			toast.error('Invalid email address.');
			return;
		}

		setIsSaving(true);
		dispatch(
			cardsAsyncActions.update({
				id: params.id,
				recipientFirstName,
				recipientLastName,
				recipientEmail,
				reminderMessage,
				deliveryAt,
			})
		);
	}, [canProceed, recipientFirstName, recipientLastName, recipientEmail, reminderMessage, deliveryAt]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardsAsyncActions.show.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardsAsyncActions.show.typePrefix)) {
			RM.consume(cardsAsyncActions.show.typePrefix);

			const card = cardsState.list.find((element) => parseInt(element.id, 10) === parseInt(params.id, 10));
			if (card) {
				setUuid(card.uuid);
				setRecipientFirstName(card.recipientFirstName);
				setRecipientLastName(card.recipientLastName);
				setRecipientEmail(card.recipientEmail);
				setReminderMessage(card.reminderMessage);
				setDeliveryAt(card.deliveryAt);
				setPicture(card.image);
				setContributors(card.contributors);
			}
		}

		if (RM.isFinished(cardsAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(cardsAsyncActions.update.typePrefix)) {
			RM.consume(cardsAsyncActions.update.typePrefix);

			toast.success('Card saved successfully.');
			history.goBack();
		}
	}, [cardsState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardsAsyncActions.show({ id: params.id }));
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ overflow: 'hidden' }}>
				<Grid container px={2} py={1}>
					<Grid item xs={12} sm={6}>
						<Box component="form" noValidate>
							<TextField
								margin="normal"
								required
								fullWidth
								name="first-name"
								label="Recipient First Name"
								type="text"
								id="first-name"
								autoComplete="first-name"
								value={recipientFirstName}
								onChange={(e) => setRecipientFirstName(e.target.value)}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="last-name"
								label="Recipient Last Name"
								type="text"
								id="last-name"
								autoComplete="last-name"
								value={recipientLastName}
								onChange={(e) => setRecipientLastName(e.target.value)}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Recipient Email Address"
								name="email"
								type="email"
								autoComplete="email"
								autoFocus
								value={recipientEmail}
								onChange={(e) => setRecipientEmail(e.target.value)}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								id="reminder-message"
								label="Reminder Message"
								name="reminder-message"
								autoComplete="reminder-message"
								autoFocus
								multiline
								minRows={4}
								value={reminderMessage}
								onChange={(e) => setReminderMessage(e.target.value)}
							/>
							<DateTimePicker
								renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
								label="Delivery At"
								value={moment.utc(deliveryAt, 'DD/MM/YYYY hh:mm A')}
								inputFormat={'DD/MM/YYYY hh:mm A'}
								onChange={(value) => {
									if (value) {
										setDeliveryAt(value.format('DD/MM/YYYY hh:mm A'));
									}
								}}
							/>
							<Button
								disabled={!canProceed || isSaving}
								onClick={handleSubmit}
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								{isSaving && <CircularProgress size={16} />}&nbsp;
								{!isSaving && 'Save'}
							</Button>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<div
							style={{
								position: 'relative',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: 'calc(420px * 0.75)',
								height: '420px',
							}}
						>
							<img
								style={{
									borderRadius: '8px',
									boxShadow:
										'0 11px 15px -7px rgba(0, 0, 0, 0.1), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
									width: '100%',
									height: '100%',
									maxWidth: '100%',
									objectFit: 'contain',
								}}
								src={picture}
							/>
						</div>
						{uuid !== '' && (
							<Button
								LinkComponent={'a'}
								target="_blank"
								referrerPolicy="no-referrer"
								href={`https://www.signthecard.com/received/${uuid}`}
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								Preview
							</Button>
						)}
					</Grid>
				</Grid>
				{contributors.length > 0 && (
					<SortableTable
						noSelection
						onRowClick={() => {}}
						onDeleteClick={() => {}}
						title={'Contributors'}
						data={contributors}
						header={[
							{
								id: 'email',
								label: 'E-Mail',
								numeric: false,
								disablePadding: true,
							},
							{
								id: 'isSigned',
								label: 'Signed',
								numeric: false,
								boolean: true,
								disablePadding: false,
							},
							{
								id: 'isContributed',
								label: 'Contributed',
								numeric: false,
								boolean: true,
								disablePadding: false,
							},
							{
								id: 'contribution',
								label: 'Contribution',
								numeric: true,
								disablePadding: false,
								currency: true,
							},
						]}
					/>
				)}
			</Paper>
		</BasicLayout>
	);
};

export default CardUpdateScreen;
