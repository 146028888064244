export function redirectTo(path: string) {
	window.location.href = path;
}

export const isEmpty = (value: string | null | undefined) => {
	if (value === null || value === undefined) {
		return true;
	}

	if (typeof value === 'string' && value.trim() === '') {
		return true;
	}

	return false;
};

export const isNotEmpty = (value: string) => {
	return !isEmpty(value);
};

export const isAnyEmpty = (values: Array<string | Date | null | undefined>) => {
	for (const value of values) {
		if (value === null || value === undefined) {
			return true;
		}

		if (typeof value === 'string' && value.trim() === '') {
			return true;
		}
	}

	return false;
};

export const isURL = (value: string | null | undefined): boolean => {
	if (isEmpty(value)) {
		return false;
	}

	let url;

	try {
		url = new URL(value!);
	} catch (_) {
		return false;
	}

	return url.protocol === 'http:' || url.protocol === 'https:';
};

export const parseBoolean = (value: string | number | null | undefined, defaultValue = false): boolean => {
	if (value === '' || value === null || value === undefined) {
		return defaultValue;
	}

	if (typeof value === 'string') {
		if (value.trim() === '') {
			return defaultValue;
		}

		const toCheck = value.trim().toLowerCase();
		if (toCheck === '1' || toCheck === 'true' || toCheck === 'yes') {
			return true;
		}
	}

	if (typeof value === 'number') {
		if (value >= 1) {
			return true;
		}
	}

	return false;
};

export const parseNumber = (value: string | null | undefined, defaultValue = 0): number => {
	if (value === '' || value === null || value === undefined) {
		return defaultValue;
	}

	if (typeof value === 'string') {
		if (value.trim() === '') {
			return defaultValue;
		}

		const toCheck = Number(value);
		if (isNaN(toCheck)) {
			return defaultValue;
		}

		return toCheck;
	}

	return Number(value);
};
