import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
	AppBar,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import SortableTable from '../../lib/components/SortableTable';
import WithdrawStatus from '../../lib/util/WithdrawStatus';
import Withdraw from '../../models/Withdraw';
import { useAppDispatch } from '../../store';
import withdrawsAsyncActions from '../../store/actions/withdraws.action';
import RequestManager from '../../store/request-manager';
import { useRequestState, useWithdrawsState } from '../../store/selectors';

interface Props {}

const WithdrawsScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const withdrawsState = useWithdrawsState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [query, setQuery] = React.useState<string>('');

	const data = React.useMemo<Withdraw[]>(() => {
		if (query.trim().length <= 0) {
			return withdrawsState.list;
		}

		return withdrawsState.list.filter((element) =>
			`${element.card.recipientFullName}${element.card.recipientEmail}`.trim().toLowerCase().includes(query)
		);
	}, [withdrawsState.list, query]);

	const handleRefresh = React.useCallback(() => {
		dispatch(withdrawsAsyncActions.index());
	}, [dispatch]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(withdrawsAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(withdrawsAsyncActions.index.typePrefix)) {
			RM.consume(withdrawsAsyncActions.index.typePrefix);
		}
	}, [withdrawsState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(withdrawsAsyncActions.index());
	}, []);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<SearchIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									fullWidth
									placeholder="Search by email address or name"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Tooltip title="Reload">
									<IconButton onClick={handleRefresh}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				{data.length === 0 && (
					<Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
						No withdraws found.
					</Typography>
				)}
				{data.length > 0 && (
					<SortableTable
						noSelection
						onRowClick={(id: string) => history.push(`/withdraws/${id}/update`)}
						onDeleteClick={() => {}}
						title={'Withdraws'}
						data={data}
						header={[
							{
								id: 'card.recipientFullName',
								label: 'Name',
								numeric: false,
								disablePadding: true,
							},
							{
								id: 'card.recipientEmail',
								label: 'E-Mail',
								numeric: false,
								disablePadding: false,
							},
							{
								id: 'status',
								label: 'Status',
								numeric: false,
								enums: [
									{
										value: WithdrawStatus.ACCEPTED,
										label: 'ACCEPTED',
									},
									{
										value: WithdrawStatus.COMPLETED,
										label: 'COMPLETED',
									},
									{
										value: WithdrawStatus.DECLINED,
										label: 'DECLINED',
									},
									{
										value: WithdrawStatus.INITIATED,
										label: 'INITIATED',
									},
								],
								disablePadding: false,
							},
							{
								id: 'createdAt',
								label: 'Created At',
								numeric: false,
								disablePadding: false,
							},
						]}
					/>
				)}
			</Paper>
		</BasicLayout>
	);
};

export default WithdrawsScreen;
