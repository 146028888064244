import React from 'react';
import BasicLayout from '../../lib/components/BasicLayout';
import CardCategoriesTab from './CardCategoriesTab';
import CardsTab from './CardsTab';
import CardTemplatesTab from './CardTemplatesTab';

interface Props {}

const CardsScreen: React.FC<Props> = () => {
	const [index, setIndex] = React.useState<number>(0);

	return (
		<BasicLayout
			setIndex={setIndex}
			navigation={[
				{
					title: 'Cards',
				},
				{
					title: 'Categories',
				},
				{
					title: 'Templates',
				},
			]}
		>
			{index === 0 && <CardsTab />}
			{index === 1 && <CardCategoriesTab />}
			{index === 2 && <CardTemplatesTab />}
		</BasicLayout>
	);
};

export default CardsScreen;
