import { validateEmail } from '@cyboticx/common';
import { useEnterKeyPress } from '@cyboticx/hooks';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
} from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import { isAnyEmpty } from '../../lib/util';
import Roles from '../../lib/util/Roles';
import { useAppDispatch } from '../../store';
import usersAsyncActions from '../../store/actions/users.action';
import RequestManager from '../../store/request-manager';
import { useRequestState } from '../../store/selectors';

interface Props {}

const UserCreateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [roleId, setRoleId] = React.useState<Roles>(Roles.USER);
	const [availableCards, setAvailableCards] = React.useState<number>(0);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([firstName, lastName, email, password]);
	}, [firstName, lastName, email, password]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		if (!validateEmail(email)) {
			toast.error('Invalid email address.');
			return;
		}

		setIsSaving(true);
		dispatch(usersAsyncActions.store({ firstName, lastName, email, password, roleId, availableCards }));
	}, [canProceed, firstName, lastName, email, password, roleId, availableCards]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(usersAsyncActions.store.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(usersAsyncActions.store.typePrefix)) {
			RM.consume(usersAsyncActions.store.typePrefix);

			toast.success('User saved successfully.');
			history.goBack();
		}
	}, [requestUpdatedAt, request.updatedAt]);

	useEnterKeyPress(handleSubmit);

	return (
		<BasicLayout>
			<Paper sx={{ px: 2, py: 1, overflow: 'hidden' }}>
				<Box maxWidth={'sm'} component="form" noValidate>
					<FormControl margin="normal" fullWidth>
						<InputLabel id="role-select-label">Role</InputLabel>
						<Select
							labelId="role-select-label"
							id="role-select"
							value={roleId}
							label="Role"
							onChange={(e) => setRoleId(parseInt(e.target.value as string, 10) as Roles)}
						>
							<MenuItem value={Roles.USER}>User</MenuItem>
							<MenuItem value={Roles.ADMIN}>Admin</MenuItem>
							<MenuItem value={Roles.SUPER_ADMIN}>Super Admin</MenuItem>
						</Select>
					</FormControl>
					<TextField
						margin="normal"
						required
						fullWidth
						name="first-name"
						label="First Name"
						type="text"
						id="first-name"
						autoComplete="first-name"
						autoFocus
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="last-name"
						label="Last Name"
						type="text"
						id="last-name"
						autoComplete="last-name"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						type="email"
						autoComplete="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="password"
						label="Password"
						name="password"
						type="password"
						autoComplete="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="available-cards"
						label="Available Cards"
						name="available-cards"
						type="number"
						autoComplete="available-cards"
						value={availableCards}
						onChange={(e) => setAvailableCards(parseInt(e.target.value, 10))}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default UserCreateScreen;
