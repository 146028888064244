import { validateEmail } from '@cyboticx/common';
import { useEnterKeyPress, useQuery } from '@cyboticx/hooks';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { isAnyEmpty, Timing } from '../../lib/util';
import { useAppDispatch } from '../../store';
import authenticationAsyncActions from '../../store/actions/authentication.action';
import RequestManager from '../../store/request-manager';
import { useAuthenticationState, useRequestState } from '../../store/selectors';
import { authenticationActions } from '../../store/slices/authentication.slice';

const Copyright = (props: any) => {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://www.signthecard.com/">
				Sign the Card
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
};

interface Props {}

const SignInScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const authentication = useAuthenticationState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState(request.updatedAt);

	const { push, replace } = useHistory();
	const { pathname } = useLocation();
	const redirectAfterLogin = useQuery().get('redirectTo');

	const [rememberMeChecked, setRememberMeChecked] = React.useState(false);

	const [email, setEmail] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');

	React.useEffect(() => {
		if (pathname === '/logout') {
			if (!authentication.isAuthenticated) {
				replace('/');
				return;
			}

			dispatch(authenticationAsyncActions.signOut());
			replace('/');
			return;
		}

		if (!authentication.isAuthenticated) return;

		if (Timing.isExpired(authentication.expiryAt)) {
			dispatch(authenticationActions.removeAuthState());
			return;
		}

		const RM = new RequestManager(request);
		if (!RM.isAvailable(authenticationAsyncActions.signIn.typePrefix)) {
			if (redirectAfterLogin) push(redirectAfterLogin);
			else push('/dashboard');
		}
	}, [authentication, redirectAfterLogin, requestUpdatedAt]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		RM.consume(authenticationAsyncActions.signOut.typePrefix);

		if (RM.isFulfilled(authenticationAsyncActions.signIn.typePrefix)) {
			RM.consume(authenticationAsyncActions.signIn.typePrefix);
			if (redirectAfterLogin) push(redirectAfterLogin);
			else push('/dashboard');
			return;
		}
	}, [authentication, requestUpdatedAt, redirectAfterLogin, request.updatedAt]);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([email, password]);
	}, [email, password]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Both fields are required.');
			return;
		}

		if (!validateEmail(email)) {
			toast.error('Invalid email address.');
			return;
		}

		dispatch(authenticationAsyncActions.signIn({ email, password }));
	}, [canProceed, email, password]);

	useEnterKeyPress(handleSubmit);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Sign in
				</Typography>
				<Box component="form" noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={rememberMeChecked}
								onChange={() => setRememberMeChecked((prev) => !prev)}
								value="remember"
								color="primary"
							/>
						}
						label="Remember me"
					/>
					<Button
						disabled={!canProceed}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Sign In
					</Button>
					{/* <Grid container>
						<Grid item xs>
							<Link href="#" variant="body2">
								Forgot password?
							</Link>
						</Grid>
						<Grid item>
							<Link href="#" variant="body2">
								{"Don't have an account? Sign Up"}
							</Link>
						</Grid>
					</Grid> */}
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</Container>
	);
};

export default SignInScreen;
