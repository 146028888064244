import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
	AppBar,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import SortableTable from '../../lib/components/SortableTable';
import CardPack from '../../models/CardPack';
import { useAppDispatch } from '../../store';
import cardPacksAsyncActions from '../../store/actions/cardPacks.action';
import RequestManager from '../../store/request-manager';
import { useCardPacksState, useRequestState } from '../../store/selectors';

interface Props {}

const CardPacksScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const cardPacksState = useCardPacksState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [query, setQuery] = React.useState<string>('');

	const data = React.useMemo<CardPack[]>(() => {
		if (query.trim().length <= 0) {
			return cardPacksState.list;
		}

		return cardPacksState.list.filter((element) => element.name.trim().toLowerCase().includes(query));
	}, [cardPacksState.list, query]);

	const handleRefresh = React.useCallback(() => {
		dispatch(cardPacksAsyncActions.index());
	}, [dispatch]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardPacksAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardPacksAsyncActions.index.typePrefix)) {
			RM.consume(cardPacksAsyncActions.index.typePrefix);
		}
	}, [cardPacksState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardPacksAsyncActions.index());
	}, []);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
				<AppBar
					position="static"
					color="default"
					elevation={0}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					<Toolbar>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<SearchIcon color="inherit" sx={{ display: 'block' }} />
							</Grid>
							<Grid item xs>
								<TextField
									fullWidth
									placeholder="Search by name"
									InputProps={{
										disableUnderline: true,
										sx: { fontSize: 'default' },
									}}
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									variant="standard"
								/>
							</Grid>
							<Grid item>
								<Button
									onClick={() => history.push('/card-packs/create')}
									variant="contained"
									sx={{ mr: 1 }}
								>
									Add card pack
								</Button>
								<Tooltip title="Reload">
									<IconButton onClick={handleRefresh}>
										<RefreshIcon color="inherit" sx={{ display: 'block' }} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				{data.length === 0 && (
					<Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
						No card packs found.
					</Typography>
				)}
				{data.length > 0 && (
					<SortableTable
						onRowClick={(id: string) => history.push(`/card-packs/${id}/update`)}
						onDeleteClick={(ids: string[]) =>
							ids.forEach((id) => dispatch(cardPacksAsyncActions.destroy({ id })))
						}
						title={'Card Packs'}
						data={data}
						header={[
							{
								id: 'name',
								label: 'Name',
								numeric: false,
								disablePadding: true,
							},
							{
								id: 'cardsAmount',
								label: 'Cards',
								numeric: true,
								disablePadding: false,
							},
							{
								id: 'isEnabled',
								label: 'Enabled',
								numeric: false,
								disablePadding: false,
								boolean: true,
							},
							{
								id: 'pricePerCard',
								label: 'Price Per Card',
								numeric: true,
								currency: true,
								disablePadding: false,
							},
						]}
					/>
				)}
			</Paper>
		</BasicLayout>
	);
};

export default CardPacksScreen;
