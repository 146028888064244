import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import User from 'models/User';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import ErrorResponse from 'network/responses/ErrorResponse';
import { toast } from 'react-hot-toast';
import authenticationAsyncActions from 'store/actions/authentication.action';
import userAsyncActions from 'store/actions/user.action';
import { CPA, UserState } from 'store/types';
import { requestActions } from './request.slice';

const initialState: UserState = {
	id: '',
	firstName: '',
	lastName: '',
	fullName: '',
	email: '',
	availableCards: 0,
	roleId: -1,
	cards: [],
	cardsAmount: 0,
	createdAt: '',
	updatedAt: '',
};

const fillAuth = (state: WritableDraft<typeof initialState>, { payload }: CPA<AuthenticationResponse>) => {
	state.id = payload.user.id;
	state.firstName = payload.user.firstName;
	state.lastName = payload.user.lastName;
	state.fullName = payload.user.fullName;
	state.email = payload.user.email;
	state.roleId = payload.user.roleId;
	state.createdAt = payload.user.createdAt;
	state.updatedAt = payload.user.updatedAt;
};

const fillUser = (state: WritableDraft<typeof initialState>, { payload }: CPA<User>) => {
	state.id = payload.id;
	state.firstName = payload.firstName;
	state.lastName = payload.lastName;
	state.fullName = payload.fullName;
	state.email = payload.email;
	state.roleId = payload.roleId;
	state.createdAt = payload.createdAt;
	state.updatedAt = payload.updatedAt;
};

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: fillAuth,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		// [updateProfile.fulfilled.type]: (state, { payload }: PA<User>) => {
		// 	state.firstName = payload.firstName;
		// 	state.lastName = payload.lastName;

		// 	toast.success('Your profile saved successfully.');
		// },
		// [updateProfilePicture.fulfilled.type]: (state, { payload }: PA<Admin>) => {
		// 	state.profilePicture = payload.profilePicture;

		// 	toast.success('Your profile picture saved successfully.');
		// },
		// [updateProfile.rejected.type]: (state, { payload }: PA<ErrorResponse>) => {
		// 	toast.error(payload.error.list[0].msg);

		// 	if (payload.error.status === 401) {
		// 		return initialState;
		// 	}

		// 	return state;
		// },
		[userAsyncActions.refreshUser.fulfilled.type]: (state, action: CPA<User>) => {
			fillUser(state, action);
			action.dispatch(
				requestActions.fulfilled({
					name: userAsyncActions.refreshUser.typePrefix,
					message: '',
					payload: {},
				})
			);
		},
		[userAsyncActions.refreshUser.rejected.type]: (state, { payload, dispatch }: CPA<ErrorResponse>) => {
			toast.error(payload.error.list[0].msg);

			if (payload.error.status === 401) {
				state = initialState;
			}

			dispatch(
				requestActions.rejected({
					name: userAsyncActions.refreshUser.typePrefix,
					message: '',
					payload: {},
				})
			);

			return state;
		},
		[authenticationAsyncActions.resetPassword.fulfilled.type]: fillAuth,
		// [changePassword.fulfilled.type]: () => {
		// 	toast.success('Your password changed successfully.');
		// },
		// [changePassword.rejected.type]: (state, { payload }: PA<ErrorResponse>) => {
		// 	toast.error(payload.error.list[0].msg);

		// 	if (payload.error.status === 401) {
		// 		return initialState;
		// 	}

		// 	return state;
		// },
	},
});

export const userActions = slice.actions;

export default slice.reducer;
