import { useLocalStorage } from '@cyboticx/hooks';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import { useAppDispatch } from 'store';
import { authenticationActions } from './store/slices/authentication.slice';

const Bootstrap = () => {
	const dispatch = useAppDispatch();
	const [accessToken] = useLocalStorage('accessToken', '');

	React.useEffect(() => {
		if (accessToken) return;
		dispatch(authenticationActions.removeAuthState());
	}, [accessToken]);

	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};

export default Bootstrap;
