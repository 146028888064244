import { createSlice } from '@reduxjs/toolkit';
import User from 'models/User';
import ErrorResponse from 'network/responses/ErrorResponse';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { CPA, UsersState } from 'store/types';
import { Timing } from '../../lib/util';
import usersAsyncActions from '../actions/users.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: UsersState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[usersAsyncActions.index.fulfilled.type]: (state, action: CPA<User[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[usersAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[usersAsyncActions.show.fulfilled.type]: (state, action: CPA<User>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[usersAsyncActions.show.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[usersAsyncActions.store.fulfilled.type]: (state, action: CPA<User>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[usersAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[usersAsyncActions.update.fulfilled.type]: (state, action: CPA<User>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[usersAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[usersAsyncActions.destroy.fulfilled.type]: (state, action: CPA<string>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[usersAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
	},
});

export const usersActions = slice.actions;

export default slice.reducer;
