import { useEnterKeyPress } from '@cyboticx/hooks';
import { Box, Button, CircularProgress, FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import { isAnyEmpty } from '../../lib/util';
import { useAppDispatch } from '../../store';
import cardPacksAsyncActions from '../../store/actions/cardPacks.action';
import RequestManager from '../../store/request-manager';
import { useCardPacksState, useRequestState } from '../../store/selectors';

interface Props {}

const CardPackUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useParams<{
		id: string;
	}>();

	const cardPacksState = useCardPacksState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>('');
	const [savingPercentageDescription, setSavingPercentageDescription] = React.useState<string>('');
	const [pricePerCard, setPricePerCard] = React.useState<number>(0.0);
	const [cardsAmount, setCardsAmount] = React.useState<number>(0);
	const [isEnabled, setIsEnabled] = React.useState<boolean>(true);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([name, savingPercentageDescription]);
	}, [name, savingPercentageDescription]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			cardPacksAsyncActions.update({
				id: params.id,
				name,
				savingPercentageDescription,
				cardsAmount,
				pricePerCard,
				isEnabled,
			})
		);
	}, [canProceed, name, savingPercentageDescription, cardsAmount, pricePerCard, isEnabled]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardPacksAsyncActions.show.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardPacksAsyncActions.show.typePrefix)) {
			RM.consume(cardPacksAsyncActions.show.typePrefix);

			const pack = cardPacksState.list.find((element) => parseInt(element.id, 10) === parseInt(params.id, 10));
			if (pack) {
				setName(pack.name);
				setSavingPercentageDescription(pack.savingPercentageDescription);
				setCardsAmount(pack.cardsAmount);
				setPricePerCard(pack.pricePerCard);
				setIsEnabled(pack.isEnabled);
			}
		}

		if (RM.isFinished(cardPacksAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(cardPacksAsyncActions.update.typePrefix)) {
			RM.consume(cardPacksAsyncActions.update.typePrefix);

			toast.success('Card Pack saved successfully.');
			history.goBack();
		}
	}, [cardPacksState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardPacksAsyncActions.show({ id: params.id }));
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ overflow: 'hidden' }}>
				<Box px={2} py={1} maxWidth={'sm'} component="form" noValidate>
					<TextField
						margin="normal"
						required
						fullWidth
						name="name"
						label="Name"
						type="text"
						id="name"
						autoComplete="name"
						autoFocus
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="saving-percentage-description"
						label="Saving Percentage Description"
						type="text"
						id="saving-percentage-description"
						autoComplete="saving-percentage-description"
						value={savingPercentageDescription}
						onChange={(e) => setSavingPercentageDescription(e.target.value)}
					/>
					<FormControlLabel
						control={<Switch checked={isEnabled} onChange={(_, newValue) => setIsEnabled(newValue)} />}
						label="Is Enabled"
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="cards-amount"
						label="Cards Amount"
						name="cards-amount"
						type="number"
						autoComplete="cards-amount"
						value={cardsAmount}
						onChange={(e) => setCardsAmount(parseInt(e.target.value, 10))}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="price-per-card"
						label="Price Per Card"
						name="price-per-card"
						type="number"
						autoComplete="price-per-card"
						value={pricePerCard}
						onChange={(e) => setPricePerCard(parseFloat(e.target.value))}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default CardPackUpdateScreen;
