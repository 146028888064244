import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import Company from '../../models/Company';
import CreateCompanyRequest from '../../network/requests/CreateCompanyRequest';
import DeleteCompanyRequest from '../../network/requests/DeleteCompanyRequest';
import ShowCompanyRequest from '../../network/requests/ShowCompanyRequest';
import UpdateCompanyRequest from '../../network/requests/UpdateCompanyRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('companies/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, Company[]>('/companies');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('companies/show', async ({ id }: ShowCompanyRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, Company>(`/companies/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const store = createAsyncThunk('companies/store', async (payload: CreateCompanyRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<CreateCompanyRequest, Company>(`/companies`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('companies/update', async ({ id, ...payload }: UpdateCompanyRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UpdateCompanyRequest, 'id'>, Company>(`/companies/${id}`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const destroy = createAsyncThunk('companies/destroy', async ({ id }: DeleteCompanyRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<never, OkResponse>(`/companies/${id}`);

		return id;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const companiesAsyncActions = {
	index,
	show,
	store,
	update,
	destroy,
};

export default companiesAsyncActions;
