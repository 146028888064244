import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import User from '../../models/User';
import CreateUserRequest from '../../network/requests/CreateUserRequest';
import DeleteUserRequest from '../../network/requests/DeleteUserRequest';
import ShowUserRequest from '../../network/requests/ShowUserRequest';
import UpdateUserRequest from '../../network/requests/UpdateUserRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('users/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, User[]>('/users');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('users/show', async ({ id }: ShowUserRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, User>(`/users/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const store = createAsyncThunk('users/store', async (payload: CreateUserRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<CreateUserRequest, User>('/users', payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('users/update', async ({ id, ...payload }: UpdateUserRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UpdateUserRequest, 'id'>, User>(`/users/${id}`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const destroy = createAsyncThunk('users/destroy', async ({ id }: DeleteUserRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<never, OkResponse>(`/users/${id}`);

		return id;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const usersAsyncActions = {
	index,
	show,
	store,
	update,
	destroy,
};

export default usersAsyncActions;
