import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'lib/util';
import ForgotPasswordRequest from 'network/requests/ForgotPasswordRequest';
import ResetPasswordRequest from 'network/requests/ResetPasswordRequest';
import SignInRequest from 'network/requests/SignInRequest';
import AuthenticationResponse from 'network/responses/AuthenticationResponse';
import OkResponse from 'network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const signIn = createAsyncThunk('authentication/sign-in', async (payload: SignInRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(signIn.typePrefix));
	try {
		const response = await API.post<SignInRequest, AuthenticationResponse>('/auth/sign-in', payload);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const forgotPassword = createAsyncThunk(
	'authentication/forgot-password',
	async (payload: ForgotPasswordRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(forgotPassword.typePrefix));
		try {
			const response = await API.post<ForgotPasswordRequest, OkResponse>('/auth/forgot-password', payload);
			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const resetPassword = createAsyncThunk(
	'authentication/reset-password',
	async (payload: ResetPasswordRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(resetPassword.typePrefix));
		try {
			const response = await API.post<ResetPasswordRequest, AuthenticationResponse>(
				'/auth/reset-password',
				payload
			);
			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const signOut = createAsyncThunk('authentication/sign-out', async (_, thunkApi) => {
	thunkApi.dispatch(requestActions.started(signOut.typePrefix));
	try {
		const response = await API.get<any, OkResponse>('/auth/sign-out');
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue({ error });
	}
});

const authenticationAsyncActions = {
	signIn,
	forgotPassword,
	resetPassword,
	signOut,
};

export default authenticationAsyncActions;
