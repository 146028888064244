import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { CPA, SettingsState } from 'store/types';
import { Timing } from '../../lib/util';
import Setting from '../../models/Setting';
import settingsAsyncActions from '../actions/settings.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: SettingsState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[settingsAsyncActions.index.fulfilled.type]: (state, action: CPA<Setting[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[settingsAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[settingsAsyncActions.update.fulfilled.type]: (state, action: CPA<Setting>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[settingsAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
	},
});

export const settingsActions = slice.actions;

export default slice.reducer;
