import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import UsersScreen from 'screens/UsersScreen';
import { useAuthenticationState } from 'store/selectors';
import CardCategoryCreateScreen from '../screens/CardCategoryCreateScreen';
import CardCategoryUpdateScreen from '../screens/CardCategoryUpdateScreen';
import CardPackCreateScreen from '../screens/CardPackCreateScreen';
import CardPacksScreen from '../screens/CardPacksScreen';
import CardPackUpdateScreen from '../screens/CardPackUpdateScreen';
import CardsScreen from '../screens/CardsScreen';
import CardTemplateCreateScreen from '../screens/CardTemplateCreateScreen';
import CardTemplateUpdateScreen from '../screens/CardTemplateUpdateScreen';
import CardUpdateScreen from '../screens/CardUpdateScreen';
import CompaniesScreen from '../screens/CompaniesScreen';
import CompanyCreateScreen from '../screens/CompanyCreateScreen';
import CompanyUpdateScreen from '../screens/CompanyUpdateScreen';
import SettingsUpdateScreen from '../screens/SettingsUpdateScreen';
import SignInScreen from '../screens/SignInScreen';
import UserCreateScreen from '../screens/UserCreateScreen';
import UserUpdateScreen from '../screens/UserUpdateScreen';
import WithdrawsScreen from '../screens/WithdrawsScreen';
import WithdrawUpdateScreen from '../screens/WithdrawUpdateScreen';
import Authenticated from './authenticated';
import Public from './public';

interface Props {}

const Routes: React.FC<Props> = () => {
	const { isAuthenticated } = useAuthenticationState();

	return (
		<>
			<Switch>
				<Public exact path={['/', '/logout']} component={SignInScreen} />
				<Authenticated exact path="/users" component={UsersScreen} />
				<Authenticated exact path="/users/create" component={UserCreateScreen} />
				<Authenticated exact path="/users/:id(\d+)/update" component={UserUpdateScreen} />
				<Authenticated exact path="/cards" component={CardsScreen} />
				<Authenticated exact path="/cards/:id(\d+)/update" component={CardUpdateScreen} />
				<Authenticated exact path="/card-categories/create" component={CardCategoryCreateScreen} />
				<Authenticated exact path="/card-categories/:id(\d+)/update" component={CardCategoryUpdateScreen} />
				<Authenticated exact path="/card-templates/create" component={CardTemplateCreateScreen} />
				<Authenticated exact path="/card-templates/:id(\d+)/update" component={CardTemplateUpdateScreen} />
				<Authenticated exact path="/companies" component={CompaniesScreen} />
				<Authenticated exact path="/companies/create" component={CompanyCreateScreen} />
				<Authenticated exact path="/companies/:id(\d+)/update" component={CompanyUpdateScreen} />
				<Authenticated exact path="/card-packs" component={CardPacksScreen} />
				<Authenticated exact path="/card-packs/create" component={CardPackCreateScreen} />
				<Authenticated exact path="/card-packs/:id(\d+)/update" component={CardPackUpdateScreen} />
				<Authenticated exact path="/withdraws" component={WithdrawsScreen} />
				<Authenticated exact path="/withdraws/:id(\d+)/update" component={WithdrawUpdateScreen} />
				<Authenticated exact path="/settings" component={SettingsUpdateScreen} />

				{!isAuthenticated && (
					<Public path="*">
						<Redirect to="/" />
					</Public>
				)}

				{isAuthenticated && (
					<Authenticated path="*">
						<Redirect to={'/users'} />
					</Authenticated>
				)}
			</Switch>
		</>
	);
};

export default Routes;
