import { useEnterKeyPress } from '@cyboticx/hooks';
import { Box, Button, CircularProgress, FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import { isAnyEmpty } from '../../lib/util';
import { useAppDispatch } from '../../store';
import companiesAsyncActions from '../../store/actions/companies.action';
import RequestManager from '../../store/request-manager';
import { useRequestState } from '../../store/selectors';

interface Props {}

const CompanyCreateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>('');
	const [suffix, setSuffix] = React.useState<string>('');
	const [isEnabled, setIsEnabled] = React.useState<boolean>(true);
	const [availableCards, setAvailableCards] = React.useState<number>(0);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([name, suffix]);
	}, [name, suffix]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(companiesAsyncActions.store({ name, suffix, isEnabled, availableCards }));
	}, [canProceed, name, suffix, isEnabled, availableCards]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(companiesAsyncActions.store.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(companiesAsyncActions.store.typePrefix)) {
			RM.consume(companiesAsyncActions.store.typePrefix);

			toast.success('Company saved successfully.');
			history.goBack();
		}
	}, [requestUpdatedAt, request.updatedAt]);

	useEnterKeyPress(handleSubmit);

	return (
		<BasicLayout>
			<Paper sx={{ px: 2, py: 1, overflow: 'hidden' }}>
				<Box maxWidth={'sm'} component="form" noValidate>
					<TextField
						margin="normal"
						required
						fullWidth
						name="name"
						label="Name"
						type="text"
						id="name"
						autoComplete="name"
						autoFocus
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="suffix"
						label="Domain"
						type="text"
						id="suffix"
						autoComplete="suffix"
						value={suffix}
						onChange={(e) => setSuffix(e.target.value)}
					/>
					<FormControlLabel
						control={<Switch checked={isEnabled} onChange={(_, newValue) => setIsEnabled(newValue)} />}
						label="Is Enabled"
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="available-cards"
						label="Available Cards"
						name="available-cards"
						type="number"
						autoComplete="available-cards"
						value={availableCards}
						onChange={(e) => setAvailableCards(parseInt(e.target.value, 10))}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default CompanyCreateScreen;
