import { LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { ThemeProvider } from '@mui/system';
import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import store from 'store';
import Bootstrap from './Bootstrap';
import theme from './lib/theme';
import reportWebVitals from './reportWebVitals';
import './style/index.scss';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<ThemeProvider theme={theme}>
					<Toaster
						toastOptions={{
							className: 'border border-light-100',
						}}
					/>
					<Bootstrap />
				</ThemeProvider>
			</LocalizationProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
