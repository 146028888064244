import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import CardTemplate from '../../models/CardTemplate';
import CreateCardTemplateRequest from '../../network/requests/CreateCardTemplateRequest';
import DeleteCardTemplateRequest from '../../network/requests/DeleteCardTemplateRequest';
import ShowCardTemplateRequest from '../../network/requests/ShowCardTemplateRequest';
import UpdateCardTemplateRequest from '../../network/requests/UpdateCardTemplateRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('card-templates/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, CardTemplate[]>('/card-templates');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('card-templates/show', async ({ id }: ShowCardTemplateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, CardTemplate>(`/card-templates/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const store = createAsyncThunk('card-templates/store', async (payload: CreateCardTemplateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));

	const formData = new FormData();

	formData.append('file', payload.picture, payload.picture.name);
	formData.append('categoryId', payload.categoryId);
	formData.append('name', payload.name);
	formData.append('isFeatured', payload.isFeatured ? 'true' : 'false');
	formData.append('isVisible', payload.isVisible ? 'true' : 'false');
	formData.append('availableFrom', payload.availableFrom ? payload.availableFrom : '');
	formData.append('availableTo', payload.availableTo ? payload.availableTo : '');

	try {
		const response = await API.post<unknown, CardTemplate>(`/card-templates`, formData);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk(
	'card-templates/update',
	async ({ id, ...payload }: UpdateCardTemplateRequest, thunkApi) => {
		thunkApi.dispatch(requestActions.started(update.typePrefix));

		const formData = new FormData();

		if (payload.picture) {
			formData.append('file', payload.picture, payload.picture.name);
		}
		formData.append('categoryId', payload.categoryId);
		formData.append('name', payload.name);
		formData.append('isFeatured', payload.isFeatured ? 'true' : 'false');
		formData.append('isVisible', payload.isVisible ? 'true' : 'false');
		formData.append('availableFrom', payload.availableFrom ? payload.availableFrom : '');
		formData.append('availableTo', payload.availableTo ? payload.availableTo : '');

		try {
			const response = await API.put<unknown, CardTemplate>(`/card-templates/${id}`, formData);

			return response.data;
		} catch (error) {
			return thunkApi.rejectWithValue({ payload, error });
		}
	}
);

const destroy = createAsyncThunk('card-templates/destroy', async ({ id }: DeleteCardTemplateRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<never, OkResponse>(`/card-templates/${id}`);

		return id;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const cardTemplatesAsyncActions = {
	index,
	show,
	store,
	update,
	destroy,
};

export default cardTemplatesAsyncActions;
