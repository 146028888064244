import { useEnterKeyPress } from '@cyboticx/hooks';
import { DateTimePicker } from '@mui/lab';
import { Box, Button, CircularProgress, FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import { isAnyEmpty } from '../../lib/util';
import { useAppDispatch } from '../../store';
import cardCategoriesAsyncActions from '../../store/actions/cardCategories.action';
import RequestManager from '../../store/request-manager';
import { useRequestState } from '../../store/selectors';

interface Props {}

const CardCategoryCreateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>('');
	const [isFeatured, setIsFeatured] = React.useState<boolean>(false);
	const [isVisible, setIsVisible] = React.useState<boolean>(true);
	const [availableFrom, setAvailableFrom] = React.useState<string | null>(null);
	const [availableTo, setAvailableTo] = React.useState<string | null>(null);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([name]);
	}, [name]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			cardCategoriesAsyncActions.store({
				name,
				isFeatured,
				isVisible,
				availableFrom,
				availableTo,
			})
		);
	}, [canProceed, name, isFeatured, isVisible, availableFrom, availableTo]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardCategoriesAsyncActions.store.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(cardCategoriesAsyncActions.store.typePrefix)) {
			RM.consume(cardCategoriesAsyncActions.store.typePrefix);

			toast.success('Card Category saved successfully.');
			history.goBack();
		}
	}, [requestUpdatedAt, request.updatedAt]);

	useEnterKeyPress(handleSubmit);

	return (
		<BasicLayout>
			<Paper sx={{ px: 2, py: 1, overflow: 'hidden' }}>
				<Box maxWidth={'sm'} component="form" noValidate>
					<TextField
						margin="normal"
						required
						fullWidth
						name="name"
						label="Name"
						type="text"
						id="name"
						autoComplete="name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<FormControlLabel
						control={<Switch checked={isFeatured} onChange={(_, newValue) => setIsFeatured(newValue)} />}
						label="Is Featured"
					/>
					<FormControlLabel
						control={<Switch checked={isVisible} onChange={(_, newValue) => setIsVisible(newValue)} />}
						label="Is Visible"
					/>
					<DateTimePicker
						renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
						label="Available From"
						value={availableFrom ? moment.utc(availableFrom, 'YYYY-MM-DD hh:mm A') : null}
						inputFormat={'DD/MM/YYYY hh:mm A'}
						onChange={(value) => {
							setAvailableFrom(value ? value.format('YYYY-MM-DD hh:mm A') : null);
						}}
					/>
					<DateTimePicker
						renderInput={(props) => <TextField margin="normal" fullWidth {...props} />}
						label="Available To"
						value={availableTo ? moment.utc(availableTo, 'YYYY-MM-DD hh:mm A') : null}
						inputFormat={'DD/MM/YYYY hh:mm A'}
						onChange={(value) => {
							setAvailableTo(value ? value.format('YYYY-MM-DD hh:mm A') : null);
						}}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default CardCategoryCreateScreen;
