import { useEnterKeyPress } from '@cyboticx/hooks';
import { Box, Button, CircularProgress, FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import SortableTable from '../../lib/components/SortableTable';
import { isAnyEmpty } from '../../lib/util';
import Company from '../../models/Company';
import User from '../../models/User';
import { useAppDispatch } from '../../store';
import companiesAsyncActions from '../../store/actions/companies.action';
import usersAsyncActions from '../../store/actions/users.action';
import RequestManager from '../../store/request-manager';
import { useCompaniesState, useRequestState, useUsersState } from '../../store/selectors';

interface Props {}

const CompanyUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useParams<{
		id: string;
	}>();

	const usersState = useUsersState();
	const companiesState = useCompaniesState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [initialized, setInitialized] = React.useState<boolean>(false);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [silent, setSilent] = React.useState<boolean>(false);
	const [name, setName] = React.useState<string>('');
	const [suffix, setSuffix] = React.useState<string>('');
	const [userIds, setUserIds] = React.useState<string[]>([]);
	const [isEnabled, setIsEnabled] = React.useState<boolean>(true);
	const [availableCards, setAvailableCards] = React.useState<number>(0);

	const company = React.useMemo<Company | undefined>(
		() => companiesState.list.find((element) => parseInt(element.id, 10) === parseInt(params.id, 10)),
		[companiesState.list, params.id]
	);

	const users = React.useMemo<User[]>(() => {
		if (suffix === '' || !suffix.includes('.')) {
			return [];
		}

		let list = [...usersState.list].filter((element) => element.email.toLowerCase().includes(suffix.toLowerCase()));
		list = list.map((element) => ({
			included: userIds.map((element) => parseInt(element, 10)).includes(parseInt(element.id, 10)),
			...element,
		}));

		return list;
	}, [usersState.list, suffix, userIds]);

	const canProceed = React.useMemo<boolean>(() => {
		return !isAnyEmpty([name, suffix]);
	}, [name, suffix]);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			companiesAsyncActions.update({
				id: params.id,
				name,
				suffix,
				userIds: userIds.length === 0 ? null : userIds.join(','),
				isEnabled,
				availableCards,
			})
		);
	}, [canProceed, name, suffix, userIds, isEnabled, availableCards]);

	const toggleUser = React.useCallback(
		(id: string) => {
			const newUserIds = [...userIds];

			const findIndex = userIds.findIndex((element) => parseInt(element, 10) === parseInt(id, 10));
			if (findIndex === -1) {
				newUserIds.push(id);
			} else {
				newUserIds.splice(findIndex, 1);
			}

			setUserIds(newUserIds);
		},
		[userIds]
	);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFulfilled(companiesAsyncActions.show.typePrefix)) {
			if (RM.isFulfilled(usersAsyncActions.index.typePrefix)) {
				RM.consume(companiesAsyncActions.show.typePrefix);
				RM.consume(usersAsyncActions.index.typePrefix);

				setIsLoading(false);
			}

			const company = companiesState.list.find((element) => parseInt(element.id, 10) === parseInt(params.id, 10));
			if (company) {
				setName(company.name);
				setSuffix(company.suffix);
				setUserIds(company.userIds ? company.userIds.split(',') : []);
				setIsEnabled(company.isEnabled);
				setAvailableCards(company.availableCards);

				setInitialized(true);
			}
		}

		if (RM.isFinished(companiesAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(companiesAsyncActions.update.typePrefix)) {
			RM.consume(companiesAsyncActions.update.typePrefix);

			setSilent(false);
			if (!silent) {
				toast.success('Company saved successfully.');
				history.goBack();
			}
		}
	}, [companiesState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(companiesAsyncActions.show({ id: params.id }));
		dispatch(usersAsyncActions.index());
	}, []);

	React.useEffect(() => {
		if (!canProceed || !initialized || !company) {
			return;
		}

		if (company.userIds === userIds.join(',')) {
			return;
		}
		setSilent(true);
		setIsSaving(true);
		dispatch(
			companiesAsyncActions.update({
				id: params.id,
				name,
				suffix,
				userIds: userIds.length === 0 ? null : userIds.join(','),
				isEnabled,
				availableCards,
			})
		);
	}, [userIds]);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ overflow: 'hidden' }}>
				<Box px={2} py={1} maxWidth={'sm'} component="form" noValidate>
					<TextField
						margin="normal"
						required
						fullWidth
						name="name"
						label="Name"
						type="text"
						id="name"
						autoComplete="name"
						autoFocus
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="suffix"
						label="Domain"
						type="text"
						id="suffix"
						autoComplete="suffix"
						value={suffix}
						onChange={(e) => setSuffix(e.target.value)}
					/>
					<FormControlLabel
						control={<Switch checked={isEnabled} onChange={(_, newValue) => setIsEnabled(newValue)} />}
						label="Is Enabled"
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="available-cards"
						label="Available Cards"
						name="available-cards"
						type="number"
						autoComplete="available-cards"
						value={availableCards}
						onChange={(e) => setAvailableCards(parseInt(e.target.value, 10))}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
				{users.length > 0 && (
					<SortableTable
						noSelection
						onRowClick={toggleUser}
						onDeleteClick={() => {}}
						title={'Users'}
						data={users}
						header={[
							{
								id: 'email',
								label: 'E-Mail',
								numeric: false,
								disablePadding: true,
							},
							{
								id: 'fullName',
								label: 'Name',
								numeric: false,
								disablePadding: false,
							},
							{
								id: 'included',
								label: 'Is Enabled',
								numeric: false,
								boolean: true,
								disablePadding: false,
							},
						]}
					/>
				)}
			</Paper>
		</BasicLayout>
	);
};

export default CompanyUpdateScreen;
