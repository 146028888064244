import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {
	AppBar,
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SortableTable from '../../lib/components/SortableTable';
import Card from '../../models/Card';
import { useAppDispatch } from '../../store';
import cardsAsyncActions from '../../store/actions/cards.action';
import RequestManager from '../../store/request-manager';
import { useCardsState, useRequestState } from '../../store/selectors';

interface Props {}

const CardsTab: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const cardsState = useCardsState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [query, setQuery] = React.useState<string>('');

	const data = React.useMemo<Card[]>(() => {
		if (query.trim().length <= 0) {
			return cardsState.list;
		}

		return cardsState.list.filter((element) =>
			`${element.recipientFirstName}${element.recipientLastName}${element.recipientEmail}`
				.trim()
				.toLowerCase()
				.includes(query)
		);
	}, [cardsState.list, query]);

	const handleRefresh = React.useCallback(() => {
		dispatch(cardsAsyncActions.index());
	}, [dispatch]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(cardsAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(cardsAsyncActions.index.typePrefix)) {
			RM.consume(cardsAsyncActions.index.typePrefix);
		}
	}, [cardsState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(cardsAsyncActions.index());
	}, []);

	if (isLoading) {
		return (
			<Box
				component="main"
				height={'100%'}
				sx={{
					flex: 1,
					py: 6,
					px: 4,
					bgcolor: '#eaeff1',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
			<AppBar
				position="static"
				color="default"
				elevation={0}
				sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
			>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<SearchIcon color="inherit" sx={{ display: 'block' }} />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								placeholder="Search by email address or name"
								InputProps={{
									disableUnderline: true,
									sx: { fontSize: 'default' },
								}}
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								variant="standard"
							/>
						</Grid>
						<Grid item>
							<Tooltip title="Reload">
								<IconButton onClick={handleRefresh}>
									<RefreshIcon color="inherit" sx={{ display: 'block' }} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{data.length === 0 && (
				<Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
					No cards found.
				</Typography>
			)}
			{data.length > 0 && (
				<SortableTable
					onRowClick={(id: string) => history.push(`/cards/${id}/update`)}
					onDeleteClick={(ids: string[]) => ids.forEach((id) => dispatch(cardsAsyncActions.destroy({ id })))}
					title={'Cards'}
					data={data}
					header={[
						{
							id: 'recipientFullName',
							label: 'Recipient Name',
							numeric: false,
							disablePadding: true,
						},
						{
							id: 'recipientEmail',
							label: 'E-Mail',
							numeric: false,
							disablePadding: false,
						},
						{
							id: 'createdAt',
							label: 'Created At',
							numeric: false,
							dateFormat: 'ISO',
							disablePadding: false,
						},
					]}
				/>
			)}
		</Paper>
	);
};

export default CardsTab;
