import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface HeaderNavigationItem {
	title: string;
}

interface Props {
	title?: string;
	navigation?: HeaderNavigationItem[];
	setIndex?: React.Dispatch<React.SetStateAction<number>>;
	onDrawerToggle: () => void;
}

const Header: React.FC<Props> = ({ title = 'Dashboard', navigation = [], ...props }: Props) => {
	const [index, setIndex] = React.useState<number>(0);

	const { onDrawerToggle } = props;

	return (
		<React.Fragment>
			<AppBar component="div" color="primary" position="static" elevation={0} sx={{ zIndex: 0 }}>
				<Toolbar>
					<Grid container alignItems="center" spacing={1}>
						<Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
							<IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} edge="start">
								<MenuIcon />
							</IconButton>
						</Grid>
						<Grid item xs>
							<Typography color="inherit" variant="h5" component="h1">
								{title}
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{navigation.length > 0 && (
				<AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
					<Tabs
						value={index}
						onChange={(_, newIndex) => {
							setIndex(parseInt(newIndex, 10));
							if (props.setIndex) {
								props.setIndex(parseInt(newIndex, 10));
							}
						}}
						textColor="inherit"
					>
						{navigation.map((element, index) => (
							<Tab key={index} label={element.title} value={index} />
						))}
					</Tabs>
				</AppBar>
			)}
		</React.Fragment>
	);
};

export default Header;
