import { createSlice } from '@reduxjs/toolkit';
import ErrorResponse from 'network/responses/ErrorResponse';
import authenticationAsyncActions from 'store/actions/authentication.action';
import { CardTemplatesState, CPA } from 'store/types';
import { Timing } from '../../lib/util';
import CardTemplate from '../../models/CardTemplate';
import cardTemplatesAsyncActions from '../actions/cardTemplates.action';
import postErrorRequest from '../postErrorRequest';
import postRequest from '../postRequest';

const initialState: CardTemplatesState = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'card-templates',
	initialState,
	reducers: {},
	extraReducers: {
		[authenticationAsyncActions.signIn.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.fulfilled.type]: () => initialState,
		[authenticationAsyncActions.signOut.rejected.type]: () => initialState,
		[cardTemplatesAsyncActions.index.fulfilled.type]: (state, action: CPA<CardTemplate[]>) => {
			state.list = action.payload;
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardTemplatesAsyncActions.index.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardTemplatesAsyncActions.show.fulfilled.type]: (state, action: CPA<CardTemplate>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardTemplatesAsyncActions.show.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardTemplatesAsyncActions.store.fulfilled.type]: (state, action: CPA<CardTemplate>) => {
			state.list.push(action.payload);
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardTemplatesAsyncActions.store.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardTemplatesAsyncActions.update.fulfilled.type]: (state, action: CPA<CardTemplate>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload.id);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1, action.payload);
			} else {
				state.list.push(action.payload);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardTemplatesAsyncActions.update.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
		[cardTemplatesAsyncActions.destroy.fulfilled.type]: (state, action: CPA<string>) => {
			const findIndex = state.list.findIndex((element) => element.id === action.payload);
			if (findIndex !== -1) {
				state.list.splice(findIndex, 1);
			}
			state.updatedAt = Timing.now();

			postRequest(action);
		},
		[cardTemplatesAsyncActions.destroy.rejected.type]: (state, action: CPA<ErrorResponse>) =>
			postErrorRequest(state, action, initialState),
	},
});

export const cardTemplatesActions = slice.actions;

export default slice.reducer;
