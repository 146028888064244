import { Box, CssBaseline, Link, Typography, useMediaQuery } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import React from 'react';
import theme from '../../theme';
import Header, { HeaderNavigationItem } from '../Header';
import Navigation from '../Navigation';

const Copyright = (props: any) => {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://www.signthecard.com/">
				Sign the Card
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
};

const drawerWidth = 256;

interface Props {
	title?: string;
	navigation?: HeaderNavigationItem[];
	style?: CSSProperties;
	setIndex?: React.Dispatch<React.SetStateAction<number>>;
	children: React.ReactNode;
}

const BasicLayout: React.FC<Props> = ({ style = {}, ...props }: Props) => {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<Box sx={{ display: 'flex', minHeight: '100vh' }}>
			<CssBaseline />
			<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
				{isSmUp ? null : (
					<Navigation
						PaperProps={{ style: { width: drawerWidth } }}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
					/>
				)}
				<Navigation
					PaperProps={{ style: { width: drawerWidth } }}
					sx={{ display: { sm: 'block', xs: 'none' } }}
				/>
			</Box>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
				<Header
					setIndex={props.setIndex}
					title={props.title}
					navigation={props.navigation}
					onDrawerToggle={handleDrawerToggle}
				/>
				<Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1', ...style }}>
					{props.children}
				</Box>
				<Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
					<Copyright />
				</Box>
			</Box>
		</Box>
	);
};

export default BasicLayout;
