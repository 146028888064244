import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import authentication from './slices/authentication.slice';
import cardCategories from './slices/cardCategories.slice';
import cardPacks from './slices/cardPacks.slice';
import cards from './slices/cards.slice';
import cardTemplates from './slices/cardTemplates.slice';
import companies from './slices/companies.slice';
import request from './slices/request.slice';
import resetPassword from './slices/resetPassword.slice';
import settings from './slices/settings.slice';
import user from './slices/user.slice';
import users from './slices/users.slice';
import withdraws from './slices/withdraws.slice';

const reducer = combineReducers({
	authentication,
	cardCategories,
	cardPacks,
	cards,
	cardTemplates,
	companies,
	request,
	resetPassword,
	settings,
	user,
	users,
	withdraws,
});

const preloadedState = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')!) : {};

// This middleware will just add the property "async dispatch" to all actions
// @ts-ignore
const asyncDispatchMiddleware = (store) => (next) => (action) => {
	let syncActivityFinished = false;
	let actionQueue: Array<any> = [];

	function flushQueue() {
		actionQueue.forEach((a) => store.dispatch(a)); // flush queue
		actionQueue = [];
	}

	function dispatch(asyncAction: any) {
		actionQueue = actionQueue.concat([asyncAction]);

		if (syncActivityFinished) {
			flushQueue();
		}
	}

	const actionWithAsyncDispatch = Object.assign({}, action, { dispatch });

	const res = next(actionWithAsyncDispatch);

	syncActivityFinished = true;
	flushQueue();

	return res;
};

const store = configureStore({
	reducer,
	preloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncDispatchMiddleware),
	devTools: process.env.NODE_ENV === 'development',
});

store.subscribe(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { request, ...state } = store.getState();
	localStorage.setItem('state', JSON.stringify(state));
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
//? Typed dispatch and selector hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
