import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { Timing } from 'lib/util';
import { useAuthenticationState } from 'store/selectors';

const Authenticated = ({ component, ...rest }: RouteProps) => {
	const { isAuthenticated, expiryAt } = useAuthenticationState();
	const { pathname } = useLocation();

	return isAuthenticated && !Timing.isExpired(expiryAt) ? (
		<Route {...rest} component={component} />
	) : (
		<Redirect to={`/?redirectTo=${pathname}`} />
	);
};

export default Authenticated;
