import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import Setting from '../../models/Setting';
import UpdateSettingsRequest from '../../network/requests/UpdateSettingsRequest';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('settings/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, Setting[]>('/settings');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('settings/update', async (payload: UpdateSettingsRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UpdateSettingsRequest, 'id'>, Setting>(`/settings`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const settingsAsyncActions = {
	index,
	update,
};

export default settingsAsyncActions;
