import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../lib/util';
import CardPack from '../../models/CardPack';
import CreateCardPackRequest from '../../network/requests/CreateCardPackRequest';
import DeleteCardPackRequest from '../../network/requests/DeleteCardPackRequest';
import ShowCardPackRequest from '../../network/requests/ShowCardPackRequest';
import UpdateCardPackRequest from '../../network/requests/UpdateCardPackRequest';
import OkResponse from '../../network/responses/OkResponse';
import { requestActions } from '../slices/request.slice';

const index = createAsyncThunk('card-packs/index', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(index.typePrefix));
	try {
		const response = await API.get<any, CardPack[]>('/card-packs');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const show = createAsyncThunk('card-packs/show', async ({ id }: ShowCardPackRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(show.typePrefix));
	try {
		const response = await API.get<any, CardPack>(`/card-packs/${id}`);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const store = createAsyncThunk('card-packs/store', async (payload: CreateCardPackRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(store.typePrefix));
	try {
		const response = await API.post<CreateCardPackRequest, CardPack>(`/card-packs`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const update = createAsyncThunk('card-packs/update', async ({ id, ...payload }: UpdateCardPackRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(update.typePrefix));
	try {
		const response = await API.put<Omit<UpdateCardPackRequest, 'id'>, CardPack>(`/card-packs/${id}`, payload);

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

const destroy = createAsyncThunk('card-packs/destroy', async ({ id }: DeleteCardPackRequest, thunkApi) => {
	thunkApi.dispatch(requestActions.started(destroy.typePrefix));
	try {
		await API.delete<never, OkResponse>(`/card-packs/${id}`);

		return id;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload: { id }, error });
	}
});

const cardPacksAsyncActions = {
	index,
	show,
	store,
	update,
	destroy,
};

export default cardPacksAsyncActions;
