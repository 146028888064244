import { Request } from 'lib/util';
import User from 'models/User';
import { RootState, useTypedSelector } from '.';
import Roles from '../lib/util/Roles';
import {
	AuthenticationState,
	CardCategoriesState,
	CardPacksState,
	CardsState,
	CardTemplatesState,
	CompaniesState,
	ResetPasswordState,
	SettingsState,
	UsersState,
	WithdrawsState,
} from './types';

//! Selector hooks
export function useSelectState() {
	return useTypedSelector<RootState>((state) => state);
}

export function useRequestState() {
	return useTypedSelector<Request.State>(({ request }) => request);
}

export function useAuthenticationState() {
	return useTypedSelector<AuthenticationState>(({ authentication }) => authentication);
}

export function useUserState() {
	return useTypedSelector<User>(({ user }) => user);
}

export function useUsersState() {
	return useTypedSelector<UsersState>(({ users }) => users);
}

export function useCardsState() {
	return useTypedSelector<CardsState>(({ cards }) => cards);
}

export function useCardCategoriesState() {
	return useTypedSelector<CardCategoriesState>(({ cardCategories }) => cardCategories);
}

export function useCardTemplatesState() {
	return useTypedSelector<CardTemplatesState>(({ cardTemplates }) => cardTemplates);
}

export function useCompaniesState() {
	return useTypedSelector<CompaniesState>(({ companies }) => companies);
}

export function useCardPacksState() {
	return useTypedSelector<CardPacksState>(({ cardPacks }) => cardPacks);
}

export function useWithdrawsState() {
	return useTypedSelector<WithdrawsState>(({ withdraws }) => withdraws);
}

export function useSettingsState() {
	return useTypedSelector<SettingsState>(({ settings }) => settings);
}

export function useResetPasswordState() {
	return useTypedSelector<ResetPasswordState>(({ resetPassword }) => resetPassword);
}

export function useAuthorizationState() {
	const { roleId: role } = useUserState();

	return {
		role,
		isSuperAdmin: role === Roles.SUPER_ADMIN,
	};
}
