import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'lib/util';
import User from 'models/User';
import { requestActions } from '../slices/request.slice';
// import ChangePasswordRequest from 'network/requests/ChangePasswordRequest';
// import UpdateProfileRequest from 'network/requests/UpdateProfileRequest';
// import UpdateUserRequest from 'network/requests/UpdateUserRequest';
// import OkResponse from 'network/responses/OkResponse';
// import { ImageSource } from 'types';

// export const updateProfile = createAsyncThunk('profile/update', async (payload: UpdateProfileRequest, thunkApi) => {
// 	try {
// 		const response = await API.put<UpdateProfileRequest, Admin>('/admin/user', payload);

// 		return response.data;
// 	} catch (error) {
// 		return thunkApi.rejectWithValue({ payload, error });
// 	}
// });

// export const updateProfilePicture = createAsyncThunk(
// 	'profile/update-profile-picture',
// 	async (profilePicture: ImageSource, thunkApi) => {
// 		try {
// 			const response = await API.put<UpdateProfileRequest, Admin>('/admin/user', { profilePicture });

// 			return response.data;
// 		} catch (error) {
// 			return thunkApi.rejectWithValue({ payload: { profilePicture }, error });
// 		}
// 	}
// );

const refreshUser = createAsyncThunk('user/refresh', async (payload, thunkApi) => {
	thunkApi.dispatch(requestActions.started(refreshUser.typePrefix));
	try {
		const response = await API.get<any, User>('/user');

		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue({ payload, error });
	}
});

// export const updateUser = createAsyncThunk('user/update', async (payload: UpdateUserRequest, thunkApi) => {
// 	try {
// 		const response = await API.put<UpdateUserRequest, Admin>('/admin/user', payload);

// 		return response.data;
// 	} catch (error) {
// 		return thunkApi.rejectWithValue({ payload, error });
// 	}
// });

// export const changePassword = createAsyncThunk(
// 	'user/change-password',
// 	async (payload: ChangePasswordRequest, thunkApi) => {
// 		try {
// 			const response = await API.post<ChangePasswordRequest, OkResponse>('admin/user/change-password', payload);

// 			return response.data;
// 		} catch (error) {
// 			return thunkApi.rejectWithValue({ payload, error });
// 		}
// 	}
// );

const userAsyncActions = {
	refreshUser,
};

export default userAsyncActions;
