import { useEnterKeyPress } from '@cyboticx/hooks';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
} from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import BasicLayout from '../../lib/components/BasicLayout';
import WithdrawStatus from '../../lib/util/WithdrawStatus';
import { useAppDispatch } from '../../store';
import withdrawsAsyncActions from '../../store/actions/withdraws.action';
import RequestManager from '../../store/request-manager';
import { useRequestState, useWithdrawsState } from '../../store/selectors';

interface Props {}

const WithdrawUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const params = useParams<{
		id: string;
	}>();

	const withdrawsState = useWithdrawsState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [status, setStatus] = React.useState<WithdrawStatus>(WithdrawStatus.INITIATED);
	const [reason, setReason] = React.useState<string>('');

	const canProceed = React.useMemo<boolean>(() => {
		return true;
	}, []);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			withdrawsAsyncActions.update({
				id: params.id,
				status,
				reason,
			})
		);
	}, [canProceed, status, reason]);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(withdrawsAsyncActions.show.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(withdrawsAsyncActions.show.typePrefix)) {
			RM.consume(withdrawsAsyncActions.show.typePrefix);

			const withdraw = withdrawsState.list.find(
				(element) => parseInt(element.id, 10) === parseInt(params.id, 10)
			);
			if (withdraw) {
				setStatus(withdraw.status);
				setReason(withdraw.reason);
			}
		}

		if (RM.isFinished(withdrawsAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(withdrawsAsyncActions.update.typePrefix)) {
			RM.consume(withdrawsAsyncActions.update.typePrefix);

			toast.success('Withdraw saved successfully.');
			history.goBack();
		}
	}, [withdrawsState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(withdrawsAsyncActions.show({ id: params.id }));
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ overflow: 'hidden' }}>
				<Box px={2} py={1} maxWidth={'sm'} component="form" noValidate>
					<FormControl margin="normal" fullWidth>
						<InputLabel id="status-select-label">Status</InputLabel>
						<Select
							labelId="status-select-label"
							id="status-select"
							value={status}
							label="Status"
							onChange={(e) => setStatus(parseInt(e.target.value as string, 10) as WithdrawStatus)}
						>
							<MenuItem value={WithdrawStatus.INITIATED}>INITIATED</MenuItem>
							<MenuItem value={WithdrawStatus.ACCEPTED}>ACCEPTED</MenuItem>
							<MenuItem value={WithdrawStatus.DECLINED}>DECLINED</MenuItem>
							<MenuItem value={WithdrawStatus.COMPLETED}>COMPLETED</MenuItem>
						</Select>
					</FormControl>
					<TextField
						margin="normal"
						fullWidth
						name="reason"
						label="Reason"
						type="text"
						id="reason"
						autoComplete="reason"
						autoFocus
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default WithdrawUpdateScreen;
