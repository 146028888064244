import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import React from 'react';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, header: HeadCell[]) {
	const findHead = header.find((element) => element.id === orderBy);
	let dateFormat = undefined;
	if (findHead) {
		if (findHead.dateFormat) {
			dateFormat = findHead.dateFormat;
		}
	}

	let bValue: any = b[orderBy];
	let aValue: any = a[orderBy];

	if (dateFormat) {
		if (dateFormat === 'ISO') {
			bValue = moment.utc(bValue).valueOf();
			aValue = moment.utc(aValue).valueOf();
		} else {
			bValue = moment.utc(bValue, dateFormat).valueOf();
			aValue = moment.utc(aValue, dateFormat).valueOf();
		}
	}

	if (bValue < aValue) {
		return -1;
	}
	if (bValue > aValue) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key,
	header: HeadCell[]
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy, header)
		: (a, b) => -descendingComparator(a, b, orderBy, header);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		console.log(a);
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: string;
	label: string;
	numeric: boolean;
	boolean?: boolean;
	currency?: boolean;
	dateFormat?: string;
	enums?: {
		value: number;
		label: string;
	}[];
}

interface EnhancedTableProps {
	header: HeadCell[];
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	noSelection?: boolean;
}

const EnhancedTableHead: React.FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					{!props.noSelection && (
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								'aria-label': 'select all desserts',
							}}
						/>
					)}
				</TableCell>
				{props.header.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric || headCell.boolean ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

interface EnhancedTableToolbarProps {
	title: string;
	numSelected: number;
	onDeleteClick: () => void;
}

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = (props: EnhancedTableToolbarProps) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
					{props.title}
				</Typography>
			)}
			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton onClick={props.onDeleteClick}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

interface Props {
	title: string;
	data: any[];
	header: HeadCell[];
	onRowClick: (id: string) => void;
	onDeleteClick: (ids: string[]) => void;
	noSelection?: boolean;
}

const SortableTable: React.FC<Props> = (props: Props) => {
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<string>(props.header[0].id);
	const [selected, setSelected] = React.useState<string[]>([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(15);

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = props.data.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const getValue = (element: any, key: string, enums?: HeadCell['enums']): any => {
		if (!key.includes('.')) {
			const value = element[key];

			if (key === 'createdAt' || key === 'updatedAt') {
				return moment.utc(element[key]).format('MM/DD/YYYY HH:mm A');
			}

			if (enums) {
				const findEnum = enums.find((element) => element.value === value);
				if (findEnum) {
					return findEnum.label;
				}
			}

			return value;
		}

		const parts = key.split('.');
		let value = element;
		for (const part of parts) {
			value = value[part];
		}

		if (enums) {
			const findEnum = enums.find((element) => element.value === value);
			if (findEnum) {
				return findEnum.label;
			}
		}

		return value;
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%' }}>
				<EnhancedTableToolbar
					title={props.title}
					numSelected={selected.length}
					onDeleteClick={() => props.onDeleteClick(selected)}
				/>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
						<EnhancedTableHead
							noSelection={props.noSelection}
							header={props.header}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={props.data.length}
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
							{stableSort(props.data, getComparator(order, orderBy, props.header))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(row.id as string);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={() => props.onRowClick(row.id as string)}
											// onClick={(event) => handleClick(event, row.id as string)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												{!props.noSelection && (
													<Checkbox
														color="primary"
														checked={isItemSelected}
														onClick={(event) => handleClick(event, row.id as string)}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												)}
											</TableCell>
											<TableCell component="th" id={labelId} scope="row" padding="none">
												{getValue(row, props.header[0].id, props.header[0].enums)}
											</TableCell>
											{props.header.slice(1).map((element) => (
												<TableCell
													key={element.id}
													align={element.numeric || element.boolean ? 'right' : 'left'}
													padding={element.disablePadding ? 'none' : 'normal'}
												>
													{element.boolean && (
														<>
															{getValue(row, element.id, element.enums) ? (
																<CheckIcon />
															) : (
																<CloseIcon />
															)}
														</>
													)}
													{!element.boolean && element.currency
														? `$${parseFloat(
																getValue(row, element.id, element.enums) as string
														  ).toFixed(2)}`
														: getValue(row, element.id, element.enums)}
												</TableCell>
											))}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[15, 25, 50]}
					component="div"
					count={props.data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
};

export default SortableTable;
