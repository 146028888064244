import { createSlice, PayloadAction as PA } from '@reduxjs/toolkit';
import { Request, Timing } from 'lib/util';

type Payload = Pick<Request.Info, 'name' | 'message' | 'payload'>;

const sanitizePayloadName = (name: string): string => {
	if (name.includes('/fulfilled') || name.includes('/rejected') || name.includes('pending')) {
		const parts = name.split('/');
		parts.pop();

		return parts.join('/');
	}

	return name;
};

const initialState: Request.State = {
	list: [],
	updatedAt: Timing.now(),
};

const slice = createSlice({
	name: 'request',
	initialState,
	reducers: {
		requestStarted: (state, { payload }: PA<string>) => {
			const payloadName = sanitizePayloadName(payload);

			const list = [...state.list];
			const index = list.findIndex(({ name }) => name === payloadName);

			if (index !== -1) {
				list.splice(index, 1);
			}

			list.push({
				name: payloadName,
				status: Request.Status.PENDING,
				message: '',
				payload: {},
			});

			state.list = list;
			state.updatedAt = Timing.now();
		},

		requestFulfilled: (state, { payload }: PA<Payload>) => {
			const payloadName = sanitizePayloadName(payload.name);

			const list = [...state.list];
			const index = list.findIndex(({ name }) => name === payloadName);

			if (index !== -1) {
				list.splice(index, 1, {
					name: payloadName,
					status: Request.Status.FULFILLED,
					message: payload.message || '',
					payload: payload.payload,
				});
			}

			state.list = list;
			state.updatedAt = Timing.now();
		},

		requestRejected: (state, { payload }: PA<Payload>) => {
			const payloadName = sanitizePayloadName(payload.name);

			const list = [...state.list];
			const index = list.findIndex(({ name }) => name === payloadName);

			if (index !== -1) {
				list.splice(index, 1, {
					name: payloadName,
					status: Request.Status.REJECTED,
					message: payload.message || '',
					payload: payload.payload,
				});
			}

			state.list = list;
			state.updatedAt = Timing.now();
		},

		requestConsumed: (state, { payload }: PA<string>) => {
			const payloadName = sanitizePayloadName(payload);

			const list = [...state.list];
			const index = list.findIndex(({ name }) => name === payloadName);

			if (index !== -1) {
				list.splice(index, 1);
			}

			state.list = list;
			state.updatedAt = Timing.now();
		},
	},
});

export const requestActions = {
	started: slice.actions.requestStarted,
	rejected: slice.actions.requestRejected,
	fulfilled: slice.actions.requestFulfilled,
	consumed: slice.actions.requestConsumed,
};
export default slice.reducer;
