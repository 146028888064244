import { useEnterKeyPress } from '@cyboticx/hooks';
import { Box, Button, CircularProgress, Paper, TextField } from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import BasicLayout from '../../lib/components/BasicLayout';
import Setting from '../../models/Setting';
import { useAppDispatch } from '../../store';
import settingsAsyncActions from '../../store/actions/settings.action';
import RequestManager from '../../store/request-manager';
import { useRequestState, useSettingsState } from '../../store/selectors';

interface Props {}

const SettingsUpdateScreen: React.FC<Props> = () => {
	const dispatch = useAppDispatch();

	const settingsState = useSettingsState();
	const request = useRequestState();
	const [requestUpdatedAt] = React.useState<number>(request.updatedAt);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isSaving, setIsSaving] = React.useState<boolean>(false);
	const [settings, setSettings] = React.useState<Setting[]>([]);

	const canProceed = React.useMemo<boolean>(() => {
		return true;
	}, []);

	const handleSubmit = React.useCallback(() => {
		if (!canProceed) {
			toast.error('Please fill all required fields.');
			return;
		}

		setIsSaving(true);
		dispatch(
			settingsAsyncActions.update({
				settings,
			})
		);
	}, [canProceed, settings]);

	const getSetting = React.useCallback(
		(name: string) => {
			const findIndex = settings.findIndex((element) => element.name === name);
			if (findIndex !== -1) {
				return settings[findIndex].value;
			}

			return '';
		},
		[settings]
	);

	const setSetting = React.useCallback(
		(name: string, value: string) => {
			const findIndex = settings.findIndex((element) => element.name === name);
			if (findIndex !== -1) {
				const newSettings = [...settings];
				newSettings.splice(findIndex, 1, {
					...settings[findIndex],
					value,
				});

				setSettings(newSettings);
			}
		},
		[settings]
	);

	const formatName = React.useCallback((name: string) => {
		const formattedName = name.split('_').map((element) => `${element.charAt(0).toUpperCase()}${element.slice(1)}`);

		return formattedName.join(' ');
	}, []);

	React.useEffect(() => {
		if (requestUpdatedAt === request.updatedAt) return;
		const RM = new RequestManager(request);

		if (RM.isFinished(settingsAsyncActions.index.typePrefix)) {
			setIsLoading(false);
		}

		if (RM.isFulfilled(settingsAsyncActions.index.typePrefix)) {
			RM.consume(settingsAsyncActions.index.typePrefix);

			setSettings([...settingsState.list]);
		}

		if (RM.isFinished(settingsAsyncActions.update.typePrefix)) {
			setIsSaving(false);
		}

		if (RM.isFulfilled(settingsAsyncActions.update.typePrefix)) {
			RM.consume(settingsAsyncActions.update.typePrefix);

			toast.success('Settings saved successfully.');
		}
	}, [settingsState, requestUpdatedAt, request.updatedAt]);

	React.useEffect(() => {
		dispatch(settingsAsyncActions.index());
	}, []);

	useEnterKeyPress(handleSubmit);

	if (isLoading) {
		return (
			<BasicLayout style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<CircularProgress />
			</BasicLayout>
		);
	}

	return (
		<BasicLayout>
			<Paper sx={{ overflow: 'hidden' }}>
				<Box px={2} py={1} maxWidth={'sm'} component="form" noValidate>
					{settings.map((element, index) => (
						<TextField
							key={element.id}
							margin="normal"
							fullWidth
							name={element.name}
							label={formatName(element.name)}
							type="text"
							id={element.name}
							autoComplete={element.name}
							autoFocus={index === 0}
							value={getSetting(element.name)}
							onChange={(e) => setSetting(element.name, e.target.value)}
						/>
					))}
					<Button
						disabled={!canProceed || isSaving}
						onClick={handleSubmit}
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						{isSaving && <CircularProgress size={16} />}&nbsp;
						{!isSaving && 'Save'}
					</Button>
				</Box>
			</Paper>
		</BasicLayout>
	);
};

export default SettingsUpdateScreen;
